import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FullState } from "../../../../redux/rootReducer";
import { Box, Fade, Typography } from "@mui/material";
import { SettingsService } from "../../../../common/services/settings.service";
import { useTranslation } from "react-i18next";
import { getFaIconsStyles } from "../../../../common/styles/styles";

export const Loader = ({ fullScreen }) => {
    const { isLowResLoading, isHighResLoading } = useSelector((state: FullState) => state.generals);
    const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(isLowResLoading || isHighResLoading);
    const [loaderMessage, setLoaderMessage] = useState<string>("");
    const { t } = useTranslation();

    useEffect(() => {
        if (isLowResLoading) {
            setIsLoaderVisible(true);
            setLoaderMessage(t('VISUALIZER.LABELS.LOW_QUALITY_RENDER_MESSAGE'));
        } else if (isHighResLoading) {
            setIsLoaderVisible(false);
            // setIsLoaderVisible(true);
            // setLoaderMessage(t('VISUALIZER.LABELS.HIGH_QUALITY_RENDER_MESSAGE'));
        } else {
            setIsLoaderVisible(false);
        }
    }, [isLowResLoading, isHighResLoading]);

    const primaryColor = SettingsService.settings?.primaryColor || "#FF156D";
    const loaderSvg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                <stop offset="0" stopColor={primaryColor}></stop>
                <stop offset=".3" stopColor={primaryColor} stopOpacity=".9"></stop>
                <stop offset=".6" stopColor={primaryColor} stopOpacity=".6"></stop>
                <stop offset=".8" stopColor={primaryColor} stopOpacity=".3"></stop>
                <stop offset="1" stopColor={primaryColor} stopOpacity="0"></stop>
            </radialGradient>
            <circle transform-origin="center" fill="none" stroke="url(#a12)" strokeWidth="26" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70">
                <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="0.5" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform>
            </circle>
            <circle transform-origin="center" fill="none" opacity=".2" stroke={primaryColor} strokeWidth="26" strokeLinecap="round" cx="100" cy="100" r="70"></circle>
        </svg>
    );

    return (
        <Fade in={isLoaderVisible} timeout={300}>
            <Box
                position={fullScreen ? "fixed" : "absolute"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                sx={{ userSelect: "none", transform: "translate(-50%, -50%)" }}
                top={"50%"}
                left={"50%"}
            >
                <Box
                    position="absolute"
                    width={"64px"}
                    height={"64px"}
                    bgcolor="white"
                    borderRadius={3}
                    style={{ opacity: 0.9 }}
                />
                <Box
                    width={"50px"}
                    height={"50px"}
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    zIndex={1}
                >
                    <i className="fa-duotone fa-solid fa-spinner-third fa-2xl"
                        style={{
                            ...getFaIconsStyles(),
                            //@ts-ignore
                            "--fa-primary-color": SettingsService.settings.primaryColor,
                            "--fa-secondary-color": SettingsService.settings.secondaryColor,
                            fontSize: "40px",
                            animation: "fa-spin 0.7s infinite linear" // Inline animation for faster spin
                        }}>
                    </i>
                </Box>
            </Box>
        </Fade>
    );
};
