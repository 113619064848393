
import { useEffect, useRef, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import {BrowserView, MobileView} from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { Alert, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import { SpacePickerService } from "../modules/SpacePicker/services/space-picker.service";
import { getFaIconsStyles } from "../common/styles/styles";
import { Event, EventTypes, SpaceUploadQRCodeMobileUploadClickedEvent, SpaceUploadQRCodeScannedEvent } from "../common/models/events/event.model";
import { EventsService } from "../common/services/events.service";
import { Loader } from "../modules/Visualizer/components/Loader/Loader";
import { Check, Close } from "@mui/icons-material";

export function UploadSpace() {
    const [fadeIn, setFadeIn] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true)
            const event = new Event(EventTypes.SpaceUploadQRCodeScannedEvent, new SpaceUploadQRCodeScannedEvent())
            event.visitorId = searchParams.get("device")
            EventsService.push(event)
        }, 300)
    }, [])

    return (
        <>
        <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease' }}>
            <BrowserView>
                <QRCode value={window.location.href} />
            </BrowserView>
            <MobileView>
                <UploadSpaceOnMobile />
            </MobileView>
            
        </div>
        <Loader fullScreen/>
        </>
    );
}


export function UploadSpaceOnMobile() {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [spacePickerService] = useState<SpacePickerService>(new SpacePickerService())
    const [searchParams, setSearchParams] = useSearchParams();
    const [isSuccess, setIsSuccess] = useState(undefined)

    const handleClick = () => {
      inputRef.current.click();
      const event = new Event(EventTypes.SpaceUploadQRCodeMobileUploadClickedEvent, new SpaceUploadQRCodeMobileUploadClickedEvent())
      event.visitorId = searchParams.get("device")
      EventsService.push(event)
    };

    const handleFileUpload = async (e) => {
        // Check if file size is less than 10 Mb (10 * 1024 * 1024 bytes)
        if (e.target.files[0].size <= 10 * 1024 * 1024) {
            try{
                await spacePickerService.UploadSpace(e.target.files[0], {
                    headers: {
                        "duuid": searchParams.get("device")
                    }
                });
                setIsSuccess(true)
            } catch {
                setIsSuccess(false)
            }
        } else {
            alert("File size exceeds 10 Mb limit. Please choose a smaller file.");
        }
    }
  
    return (
      <Box  key={"add_photo"} 
            margin={2}
        >
        <input type='file' accept="image/*" id='file' onChange={handleFileUpload} ref={inputRef} style={{ display: 'none'}} />
        <Box
          sx={{
            cursor: 'pointer',
            height: "100px"
          }}
          onClick={handleClick}
          display='flex'
          alignItems='center'
          justifyContent='center'
          minHeight='100%'
          bgcolor='secondary.main'
          borderRadius='8px'
          flexDirection='column'>
          <i className="fad fa-camera-viewfinder fa-2xl" style={{...getFaIconsStyles()}}></i>
          <Typography marginInline={2} color='primary.main'>
            {t('SPACE_PICKER.UPLOAD')}
          </Typography>
  
        </Box>
        {isSuccess === true && <Alert icon={<Check fontSize="inherit" />} severity="success">
            {t('SPACE_PICKER.UPLOAD_SUCCESS')}
        </Alert>}
        {isSuccess === false && <Alert icon={<Close fontSize="inherit" />} severity="error">
            {t('SPACE_PICKER.UPLOAD_FAILURE')}
        </Alert>}
      </Box>
    );
}