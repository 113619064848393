import { Fragment, useEffect, useState, useRef } from "react"
import { ProductModel } from "../../models/product.model"
import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../redux/rootReducer"
import { Product } from "../Product/Product"
import { ScrollToTop } from "./Common/ScrollToTop"
import { WebProductDetails } from "./Web/_WebProductDetails"
import './ProductList.scss'
import { Event, EventTypes, ProductUsageEvent } from "../../../../common/models/events/event.model";
import { EventsService } from "../../../../common/services/events.service"
import { setFilter, setPreselectedSKU, setProducts, setSelectedPageForSurface, setSelectedProduct, setSelectedSKUForSurface, setStartingPage } from "../../../../redux/product/productActions"
import { setSurfaces } from "../../../../redux/surfaces/surfacesActions"
import { VisualizeModel } from "../../models/visualize.model"
import { SpaceCatalogService } from "../../services/space-catalog.service"
import ScrollToBottom from "./Common/ScrollToBottom"
import ScrollToRight from "./Common/ScrollToRight"
import { Global } from "@emotion/react"
import { setDrawerHeight, toggleLoading } from "../../../../redux/general/generalActions"
import { SettingsService } from "../../../../common/services/settings.service"
import { getFaIconsStyles } from "../../../../common/styles/styles"
import { defaultDrawerHeight } from "../../../../common/components/SwipeableDrawer"
import { shouldUseSelectedPageRef } from "../SideBar/_SearchBar"

export const ProductList = (props) => {
    const { isCatalogShownAsList } = useSelector((state: FullState) => state.generals)
    const { selectedWallsIds, isFloorSelected, isWallsSelected, isRugSelected, visualizeData } = useSelector((state: FullState) => state.surfaces)
    const { products, filter, wallsStartingPage, floorsStartingPage, offset, limit } = useSelector((state: FullState) => state.productsCatalog)
    const containerRef = useRef(null);
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const { isOnBookmarks, isMobileDrawerOpen, isLoading, drawerHeight } = useSelector((state: FullState) => state.generals)
    const [displayedSelectedProduct, setDisplayedSelectedProduct] = useState(undefined)
    const selectedProduct = useSelector((state: FullState) => state.productsCatalog.selectedProduct)
    const selectedFloorsProduct = useSelector((state: FullState) => state.productsCatalog.selectedFloorsProduct)
    const selectedWallsProduct = useSelector((state: FullState) => state.productsCatalog.selectedWallsProduct)
    const bookmarks = useSelector((state: FullState) => state.bookmarks.bookmarks)
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [displayedProducts, setDisplayedProducts] = useState(products)
    const [chunk, setChunk] = useState<any>()

    const dispatch = useDispatch()

    const spaceHasFloorSurface = () => selectedSpace.surfaces.includes("floor")
    const spaceHasWallSurface = () => selectedSpace.surfaces.includes("walls")
    const spaceHasRugsSurface = () => selectedSpace.surfaces.includes("interactive_floor")

    useEffect(() => {
        if (isOnBookmarks) {
            const selectedSurface = isFloorSelected ? "floor" : isWallsSelected ? "walls" : isRugSelected ? "interactive_floor" : ""
            setDisplayedProducts(bookmarks.filter(product => product.application_types.find(e => e === selectedSurface)))
        } else {
            if (products.length > 0) {
                fetchProducts()
            }
        }
    }, [isOnBookmarks, isFloorSelected, isRugSelected, isWallsSelected, isMobileDrawerOpen])

    useEffect(() => {
        setChunk(chunkArray(products.filter(product => product.isDefault), 3))
        if (!isOnBookmarks)
            setDisplayedProducts(products)
    }, [products])

    const fetchProducts = () => {
        /* UPDATE startingPage on surfaceChange change */
        if (isWallsSelected) {
            dispatch(setStartingPage(wallsStartingPage))
        } else {
            dispatch(setStartingPage(floorsStartingPage))
        }
        const selectedSurface = isFloorSelected ? "floor" : isWallsSelected ? "walls" : isRugSelected ? "interactive_floor" : ""
        let filterToSearch = {
            ...filter,
            application_types: [selectedSurface],
            page: selectedSurface === "floor" ? floorsStartingPage : wallsStartingPage ?? 1
        }
        if (JSON.stringify(filterToSearch) !== JSON.stringify(filter)) {
            dispatch(setProducts([]))
            if (filterToSearch.specifics) {
                filterToSearch = {
                    ...filterToSearch,
                    page: 1
                }
            }
            dispatch(setFilter(filterToSearch))
        }
        else {
            setDisplayedProducts(products)
        }
    }

    const handleProductSelect = async (product) => {
        if (!product) return;

        const selectedSurface = isFloorSelected
            ? "floor"
            : isWallsSelected
                ? "walls"
                : isRugSelected
                    ? "interactive_floor"
                    : "";

        EventsService.push(
            new Event(
                EventTypes.ProductUsageEvent,
                new ProductUsageEvent(product, selectedSpace, selectedSurface)
            )
        );

        // ✅ Ensure we re-use selectedPageBySurface when switching back
        shouldUseSelectedPageRef.current = true;

        dispatch(setPreselectedSKU(null));
        dispatch(setSelectedSKUForSurface(selectedSurface, product.sku));
        dispatch(setSelectedProduct(product));

        if (typeof offset === "number" && typeof limit === "number" && limit > 0) {
            const backendPage = Math.floor(offset / limit) + 1;
            console.log("✅ Backend page based on offset:", backendPage);
            dispatch(setSelectedPageForSurface(selectedSurface, backendPage));
            dispatch(setStartingPage(backendPage));
        }

        if (!isRugSelected) {
            let data = { ...visualizeData };

            if (isWallsSelected) {
                selectedWallsIds.forEach((wall) => {
                    data = selectProductForSurface(data, wall, "walls", product, selectedSpace.id);
                });
            }

            if (isFloorSelected) {
                data = selectProductForSurface(data, 0, "floor", product, selectedSpace.id);
            }

            // Slight delay to let Redux updates propagate
            setTimeout(() => {
                dispatch(setSurfaces(data, selectedSpace.id));
                runProcess(data);
            }, 100); // Increased to 100ms for safer propagation
        }
    };

    const selectProductForSurface = (data: VisualizeModel, surfaceId, surfaceType, product: ProductModel, actualSpace: string) => {
        const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
        let surfaces = data[surfaceType]
        let orientation = 0
        if (existingSurface) {
            surfaces = data[surfaceType].filter(surface => surface !== existingSurface)
            orientation = existingSurface.orientation
        }
        return {
            ...data,
            [surfaceType]: [...surfaces, {
                orientation: orientation,
                productId: product.id,
                surfaceKey: surfaceId
            }]
        }
    }

    const runProcess = (data) => {
        if (isFloorSelected || (isWallsSelected && selectedWallsIds.length > 0)) {
            spaceCatalogService.processProduct(data, selectedSpace)
        }

    }

    const mediaCSS = () => {
        if (props.view === "web")
            return {
                flexDirection: isCatalogShownAsList ? "column" : "row",
                flexWrap: isCatalogShownAsList ? "" : "wrap",
                justifyContent: isCatalogShownAsList ? "" : "flex-start",
                alignContent: isCatalogShownAsList ? "" : "flex-start",
                gap: isCatalogShownAsList ? "" : "5px"

            }
        if (props.view === "mobile")
            return {
                overflowY: "hidden",
                paddingBottom: 1
            }
    }

    const updateDisplayedProducts = (selectedProduct, sibling) => {
        if (selectedProduct.id !== sibling.id) {
            const selectedProductIndex = products.findIndex(product => product.id === selectedProduct.id)
            const siblings = selectedProduct.siblings.map(e => {
                if (e.id === sibling.id) {
                    return {
                        _id: sibling._id,
                        ...selectedProduct,
                        isDefault: false
                    }
                }
                else return e
            })
            let newMainProduct = { ...sibling }
            newMainProduct.siblings = siblings
            newMainProduct.isDefault = true
            const tempProducts = [...products]
            tempProducts[selectedProductIndex] = newMainProduct
            dispatch(setProducts(tempProducts))
        }
    }

    const handleSiblingSelected = async (oldProduct, product) => {
        updateDisplayedProducts(oldProduct, product)
        handleProductSelect(product)
    }

    const numberOfSurfaceSelection = () => {
        let numberOfSurfaces = 0;
        numberOfSurfaces = SettingsService.vendorHasRugs() && spaceHasRugsSurface() ? numberOfSurfaces + 1 : numberOfSurfaces
        numberOfSurfaces = SettingsService.vendorHasWalls() && spaceHasWallSurface() ? numberOfSurfaces + 1 : numberOfSurfaces
        numberOfSurfaces = SettingsService.vendorHasFloor() && spaceHasFloorSurface() ? numberOfSurfaces + 1 : numberOfSurfaces
        return numberOfSurfaces
    }

    useEffect(() => {
        setDisplayedSelectedProduct(isFloorSelected ? selectedFloorsProduct : selectedWallsProduct)
    }, [isFloorSelected, isWallsSelected, isRugSelected, selectedProduct])

    useEffect(() => {
        if (props.view === "web" && isMobileDrawerOpen && drawerHeight > defaultDrawerHeight) {
            if (!isFirstRender) {
                dispatch(setDrawerHeight(defaultDrawerHeight))
                props.showContentAs("mobile")
            } else {
                setIsFirstRender(false)
            }
        }
        setDisplayedSelectedProduct(selectedProduct)
    }, [selectedProduct])

    return (<>
        {displayedProducts && displayedProducts.length > 0 && <>
            <Box
                ref={containerRef}
                className={props.view === "web" ? "product-list-web" : "product-list-mobile"}
                sx={mediaCSS()}>
                {<Global styles={{
                    '.product-list-web': {
                        height: numberOfSurfaceSelection() === 1 ? "calc(100vh - 225px)" : "calc(100vh - 300px)"
                    },
                }} />}
                {isCatalogShownAsList && displayedProducts.filter(product => product.isDefault)?.map((item: ProductModel) => (
                    <Product
                        {...props}
                        key={item.id}
                        item={item}
                        updateDisplayedProducts={updateDisplayedProducts}
                        handleProductSelect={handleProductSelect}
                        handleSiblingSelected={handleSiblingSelected}
                        isShownAsList={isCatalogShownAsList}
                        showActions={true}
                        displayedSelectedProduct={displayedSelectedProduct}
                    />
                ))}
                {!isCatalogShownAsList && (
                    <Fragment>
                        {chunk && chunk.map((chunk, index) => (
                            <Box key={index} display="flex" justifyContent="flex-start" flexWrap="wrap" gap={"5px"}>
                                {chunk.map((item: ProductModel) => (
                                    <>
                                        <Product
                                            {...props}
                                            key={item.id}
                                            item={item}
                                            isShownAsList={isCatalogShownAsList}
                                            handleProductSelect={handleProductSelect}
                                            handleSiblingSelected={handleSiblingSelected}
                                            showActions={true}
                                            displayedSelectedProduct={displayedSelectedProduct}
                                        />
                                    </>
                                ))}
                                <WebProductDetails chunk={chunk} handleSiblingSelected={handleSiblingSelected} />
                            </Box>
                        ))}
                    </Fragment>
                )}
            </Box>
            <ScrollToTop containerRef={containerRef} />
            {displayedProducts && displayedProducts.length > 0 && <ScrollToBottom containerRef={containerRef} view={props.view} />}
            {props.view === "mobile" && <ScrollToRight containerRef={containerRef} />}
        </>}
        {isLoading && (!displayedProducts || displayedProducts.length === 0) &&
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "98%",
                backgroundColor: "white"
            }}>
                {<Box
                    width={"50px"}
                    height={"50px"}
                    zIndex={1}
                >
                    <i className="fa-duotone fa-solid fa-spinner-third fa-2xl"
                        style={{
                            ...getFaIconsStyles(),
                            //@ts-ignore
                            "--fa-primary-color": SettingsService.settings.primaryColor,
                            "--fa-secondary-color": SettingsService.settings.secondaryColor,
                            fontSize: "40px",
                            animation: "fa-spin 0.7s infinite linear" // Inline animation for faster spin
                        }}>
                    </i>
                </Box>}
            </Box>
        }
        {!isLoading && isOnBookmarks && displayedProducts.length === 0 && <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
            <Box marginBlock={2}><i className="fad fa-heart-broken fa-2xl" style={getFaIconsStyles()} ></i></Box>
            <Typography marginInline={2} textAlign={"center"} variant="body2"> Uh-oh! It seems like there are no products in your favorites right now!</Typography>
        </Box>}
    </>
    )
}

function chunkArray(array: any[], size: number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
}