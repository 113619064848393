import { Box, Typography, useMediaQuery } from "@mui/material"
import { useUIContext } from "../../../../providers/UIContext";
import { useEffect } from "react";
const ardealyAnimation = require("../../../../assets/ardealy_animation_v2.webm");
const ardealyLogo = require("../../../../assets/ardealy-blue-logo.webp");

export function Loader({ message, fullScreen }) {
    const isMobile = useMediaQuery("(max-width:600px)");
    const { setIsLoaderVisible } = useUIContext();

    useEffect(() => {
        setIsLoaderVisible(true);

        return () => {
            setIsLoaderVisible(false);
        };
    }, []);

    return (
        <Box
            sx={{
                position: fullScreen ? "fixed" : "absolute",
                top: 0, left: 0, width: "100%", height: "100vh",
                backdropFilter: "blur(60px)",
                backgroundColor: "#fff)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999999
            }}
        >
            {isMobile ? (
                <img src={ardealyLogo} style={{ width: "40%", marginTop: -200 }} />
            ) : (
                <video
                    key="desktop"
                    src={ardealyAnimation}
                    style={{ width: "10%", overflow: "hidden" }}
                    muted
                    loop
                    autoPlay
                    playsInline
                />
            )}

            <Typography
                variant="body1"
                sx={{
                    marginTop: isMobile ? -1 : 0,
                    fontWeight: 500,
                    textAlign: "center",
                    color: "#0473ff",
                }}
            >
                {message}
            </Typography>
        </Box>
    );
}