import { SpaceCatalogService } from "../../modules/Visualizer/services/space-catalog.service";
import { SET_PRODUCTS, SET_SELECTED_PRODUCT, SET_FLOORS_STARTING_PAGE, SET_WALLS_STARTING_PAGE, SET_STARTING_PAGE, SET_FILTER, SET_SELECTED_PRODUCT_RESULT, SET_SELECTED_PRODUCT_RUG_RESULT, SET_SELECTED_WALLS_PRODUCT, SET_SELECTED_FLOORS_PRODUCT } from "./productActionTypes";

export const setProducts = (products, nextPage = 2, offset = 0, limit = 15) => {
    return {
        type: SET_PRODUCTS,
        payload: {
            products,
            nextPage,
            offset,
            limit,
        },
    };
};

export const setStartingPage = (page) => {
    return {
        type: SET_STARTING_PAGE,
        payload: page,
    };
};

export const setWallsStartingPage = (page) => {
    return {
        type: SET_WALLS_STARTING_PAGE,
        payload: page,
    };
};

export const setFloorsStartingPage = (page) => {
    return {
        type: SET_FLOORS_STARTING_PAGE,
        payload: page,
    };
};

export const setFilter = (filter) => {
    return {
        type: SET_FILTER,
        payload: filter,
    };
};

export const setSelectedWallsProduct = (product) => {
    return {
        type: SET_SELECTED_WALLS_PRODUCT,
        payload: product,
    };
};


export const setSelectedFloorsProduct = (product) => {
    return {
        type: SET_SELECTED_FLOORS_PRODUCT,
        payload: product,
    };
};

export const setSelectedProduct = (product) => {
    SpaceCatalogService.updateSelectedProductId(product?.id)
    return {
        type: SET_SELECTED_PRODUCT,
        payload: product,
    };
};

export const setSelectedProductResult = (result, spaceId?) => {
    return {
        type: SET_SELECTED_PRODUCT_RESULT,
        payload: result,
        spaceId
    };
};

export const setSelectedProductRugResult = (result) => {
    return {
        type: SET_SELECTED_PRODUCT_RUG_RESULT,
        payload: result,
    };
};

export const setPreselectedSKU = (sku: string | null) => ({
    type: "SET_PRESELECTED_SKU",
    payload: sku,
});

export const setSelectedPageForSurface = (surface, page) => ({
    type: "SET_SELECTED_PAGE_FOR_SURFACE",
    payload: { surface, page }
});

export const setSelectedSKUForSurface = (surface, sku) => ({
    type: "SET_SELECTED_SKU_FOR_SURFACE",
    payload: { surface, sku }
});
