import { TOGGLE_LOADING, START_LOW_RES_LOADING, START_HIGH_RES_LOADING, STOP_LOADING, TOGGLE_BOOKMARKS, HIDE_CATALOG, SHOW_CATALOG, SET_RESULT_COMPARE, SET_CLOSE_COMPARE, TOGGLE_CATALOG_SHOWN_AS_LIST, SET_COMPANY_LOGO, TOGGLE_MOBILE_DRAWER, SET_DRAWER_HEIGHT, SET_STAGE_LOADING, CLEAR_STAGE_LOADING } from "./generalActionTypes";

export const toggleLoading = () => {
    return {
        type: TOGGLE_LOADING
    };
};

export const startLowResLoading = () => {
    return {
        type: START_LOW_RES_LOADING
    };
};

export const startHighResLoading = () => {
    return {
        type: START_HIGH_RES_LOADING
    };
};

export const stopLoading = () => {
    return {
        type: STOP_LOADING
    };
};

export const toggleBookmarks = (state) => {
    return {
        type: TOGGLE_BOOKMARKS,
        payload: state
    };
};

export const hideCatalog = () => {
    return {
        type: HIDE_CATALOG
    };
};


export const showCatalog = () => {
    return {
        type: SHOW_CATALOG
    };
};

export const setResultCompare = (value) => {
    return {
        type: SET_RESULT_COMPARE,
        payload: value
    };
};

export const setCloseCompare = (value) => {
    return {
        type: SET_CLOSE_COMPARE,
        payload: value
    };
};

export const toggleCatalogShownAsList = (state) => {
    return {
        type: TOGGLE_CATALOG_SHOWN_AS_LIST,
        payload: state
    };
};


export const setCompanyLogo = (logo) => {
    return {
        type: SET_COMPANY_LOGO,
        payload: logo
    }
}

export const toggleMobileDrawer = (value) => {
    return {
        type: TOGGLE_MOBILE_DRAWER,
        payload: value
    }
}

export const setDrawerHeight = (value) => {
    return {
        type: SET_DRAWER_HEIGHT,
        payload: value
    }
}

export const setStageLoading = (message) => ({
    type: SET_STAGE_LOADING,
    payload: message,
});

export const clearStageLoading = () => ({
    type: CLEAR_STAGE_LOADING,
});