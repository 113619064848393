import { useTheme, useMediaQuery, ImageList, Typography, Button, Box, Stack, Checkbox, FormControlLabel, FormGroup, IconButton } from "@mui/material";
import { SpaceItem } from "./SpaceItem";
import { useTranslation } from "react-i18next";
import { DeleteSpaceModal } from "./DeleteSpaceModal";
import { useState, useEffect } from "react";
import { SpacePickerService } from "../../services/space-picker.service";
import { EventsService } from "../../../../common/services/events.service";
import { Event, EventTypes, SpaceDeletedEvent } from "../../../../common/models/events/event.model";
import { SettingsService } from "../../../../common/services/settings.service";
import CheckIcon from "@mui/icons-material/Check";

const itemsGap = 15;
const columns_nb_L = 3;
const columns_nb_M = 3;
const columns_nb_S = 2;

const flatUIColors = [
  "#1abc9c", // Turquoise
  "#2ecc71", // Emerald
  "#3498db", // Peter River
  "#9b59b6", // Amethyst
  "#34495e", // Wet Asphalt
  "#16a085", // Green Sea
  "#27ae60", // Nephritis
  "#2980b9", // Belize Hole
  "#8e44ad", // Wisteria
  "#2c3e50", // Midnight Blue
  "#f1c40f", // Sun Flower
  "#e67e22", // Carrot
  "#e74c3c", // Alizarin
  "#ecf0f1", // Clouds
  "#95a5a6", // Concrete
  "#f39c12", // Orange
  "#d35400", // Pumpkin
  "#c0392b", // Pomegranate
  "#bdc3c7", // Silver
  "#7f8c8d", // Asbestos
];

/**
 * List that contains upload component, uploaded spaces, and suggested spaces
 */
export function SpacesList({ suggested }) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSMandUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMDandUp = useMediaQuery(theme.breakpoints.up('md'));
  const [spacePickerService] = useState<SpacePickerService>(new SpacePickerService());
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { t } = useTranslation();

  const [selectedSpaceForDeletion, setSelectedSpaceForDeletion] = useState(undefined);
  const [selectedSpaceForDeletionIndex, setSelectedSpaceForDeletionIndex] = useState(undefined);

  const [selectedSpaceType, setSelectedSpaceType] = useState<string | null>(null);
  const [selectedSurfaces, setSelectedSurfaces] = useState<Record<string, boolean>>({});

  const [uniqueSpaceTypes, setUniqueSpaceTypes] = useState<{ type: string; count: number }[]>([]);
  const [uniqueSurfaces, setUniqueSurfaces] = useState<string[]>([]);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * flatUIColors.length);
    return flatUIColors[randomIndex];
  };

  useEffect(() => {
    if (suggested) {
      const spaceTypeCounts = suggested.reduce((acc: Record<string, number>, item) => {
        if (item.spaceType) {
          acc[item.spaceType] = (acc[item.spaceType] || 0) + 1;
        }
        return acc;
      }, {});

      const uniqueSpaceTypes = Object.entries(spaceTypeCounts).map(([type, count]) => ({
        type,
        count: count as number,
      }));

      setUniqueSpaceTypes(uniqueSpaceTypes);

      const surfaces: string[] = suggested.flatMap(item => item.surfaces);
      const uniqueSurfaces = Array.from(new Set(surfaces));
      const initialSelectedSurfaces = uniqueSurfaces.reduce((acc, surface) => {
        acc[surface] = false;
        return acc;
      }, {} as Record<string, boolean>);
      setUniqueSurfaces(uniqueSurfaces);
      setSelectedSurfaces(initialSelectedSurfaces);
    }
  }, [suggested]);

  const handleSpaceTypeClick = (spaceType: string) => {
    setSelectedSpaceType(selectedSpaceType === spaceType ? null : spaceType);
  };

  const handleSurfaceChange = (surface: string) => {
    setSelectedSurfaces((prev) => ({
      ...prev,
      [surface]: !prev[surface],
    }));
  };

  // Determine number of columns based on screen size
  let nbOfColumns;
  if (isXS) {
    nbOfColumns = columns_nb_S;
  } else if (isSMandUp && !isMDandUp) {
    nbOfColumns = columns_nb_M;
  } else {
    nbOfColumns = columns_nb_L;
  }

  // Handle space deletion
  const handleDeleteSpace = () => {
    spacePickerService.deleteUploadedSpace(selectedSpaceForDeletion);
    setSelectedSpaceForDeletion(undefined);
    setIsDeleteDialogOpen(false);
    EventsService.push(new Event(EventTypes.SpaceDeletedEvent, new SpaceDeletedEvent(selectedSpaceForDeletion, selectedSpaceForDeletionIndex)));
  };

  const filteredSuggested = suggested?.filter(item => {
    const spaceTypeFilterPassed = !selectedSpaceType || item.spaceType === selectedSpaceType;
    const surfaceFilterPassed = Object.values(selectedSurfaces).every(value => !value) || // No surface filters selected
      item.surfaces.some(surface => selectedSurfaces[surface]);

    return spaceTypeFilterPassed && surfaceFilterPassed;
  });

  const handleSurfaceClick = (surface: string) => {
    handleSurfaceChange(surface);
  };

  return (
    <>
      <Typography sx={{ marginTop: 2 }}>{t('SPACE_PICKER.TRY_DEMO')}</Typography>

      {/* Filters + Merged Spaces Container */}
      <Box
        sx={{
          backgroundColor: `${SettingsService.settings?.primaryColor ?? 'primary.main'}08`,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 3,
          paddingBottom: 2,
          borderRadius: 2,
          marginBottom: 10,
          marginTop: 3
        }}
      >
        {/* Filters Section */}
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" flexWrap="wrap">
          {/* Space Type Filters (Left-aligned & Wraps to New Line if Needed) */}
          <Stack direction="row" spacing={2} flexWrap="wrap" sx={{ padding: isXS ? 2 : 0 }}>
            {uniqueSpaceTypes.map(({ type, count }) => (
              <Button
                key={type}
                variant="outlined"
                onClick={() => handleSpaceTypeClick(type)}
                sx={{
                  borderRadius: 2,
                  borderColor: selectedSpaceType === type ? 'transparent' : 'grey.500',
                  backgroundColor: selectedSpaceType === type
                    ? SettingsService.settings?.primaryColor ?? 'primary.main'
                    : 'white',
                  color: selectedSpaceType === type ? 'white' : 'grey.800',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: selectedSpaceType === type
                      ? `${SettingsService.settings?.primaryColor ?? 'primary.main'}80`
                      : 'grey.100',
                  },
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: getRandomColor(),
                    marginRight: 1,
                  }}
                />
                {type} ({count})
              </Button>
            ))}
          </Stack>

          {/* Surface Filter (Right-aligned) */}
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">{t('SPACE_PICKER.FILTER_SURFACE')}</Typography>
            <FormGroup row sx={{ gap: "4px" }}>
              {uniqueSurfaces.map((surface) => (
                <Box
                  key={surface}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "60px",
                    border: "1px solid",
                    borderColor: selectedSurfaces[surface] ? "primary.main" : "grey.300",
                    paddingRight: "16px",
                    paddingLeft: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSurfaceClick(surface)}
                >
                  <IconButton size="small">
                    <CheckIcon sx={{ color: selectedSurfaces[surface] ? "primary.main" : "grey.300" }} />
                  </IconButton>
                  <Typography variant="caption">{t('VISUALIZER.BUTTONS.' + surface.toUpperCase())}</Typography>
                </Box>
              ))}
            </FormGroup>
          </Stack>
        </Stack>

        {/* Merged Spaces */}
        <ImageList variant="masonry" cols={filteredSuggested?.length === 0 ? 1 : nbOfColumns} gap={itemsGap}
          sx={{ marginTop: 5 }}>
          {filteredSuggested?.map((item, index) => (
            <SpaceItem
              space={item}
              newHeight={{ height: '250px' }}
              key={index}
              index={index}
              deleteUploadedSpace={item.uploadedByVisitor ? () => {
                setIsDeleteDialogOpen(true);
                setSelectedSpaceForDeletion(item);
                setSelectedSpaceForDeletionIndex(index);
              } : undefined}
              isUploadedByUser={item.uploadedByVisitor}
            />
          ))}
        </ImageList>
      </Box>

      {/* Delete Space Modal */}
      <DeleteSpaceModal
        isOpen={isDeleteDialogOpen}
        handleClose={() => {
          setIsDeleteDialogOpen(false);
          setSelectedSpaceForDeletion(undefined);
        }}
        handleConfirm={handleDeleteSpace}
      />
    </>
  );
}
