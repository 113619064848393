import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { setCloseCompare, setDrawerHeight, setResultCompare } from "../../../../../../redux/general/generalActions"
import { FullState } from "../../../../../../redux/rootReducer"
import { setPreselectedSKU, setProducts, setSelectedProduct, setSelectedSKUForSurface } from "../../../../../../redux/product/productActions"
import { getFaIconsStyles } from "../../../../../../common/styles/styles"
import { selectFloor, selectWalls, setSelectedWalls, setSurfaces } from "../../../../../../redux/surfaces/surfacesActions"
import { useTranslation } from "react-i18next"
import { SpaceCatalogService } from "../../../../services/space-catalog.service"
import { useState } from "react"
import { VisualizeModel } from "../../../../models/visualize.model"
import { closedDrawerHeight, defaultDrawerHeight } from "../../../../../../common/components/SwipeableDrawer"
import { ResultActionsMobileVariantsDialog } from "./_ResultActionsMobileVariantsDialog"
import { EventsService } from "../../../../../../common/services/events.service"
import { Event, EventTypes, ProductRotatedEvent, ProductUsageEvent } from "../../../../../../common/models/events/event.model"
import { ProductModel } from "../../../../models/product.model"
import { getActiveSurface } from "../../../../../../redux/surfaces/surfacesReducer"
import { SettingsService } from "../../../../../../common/services/settings.service"

export const ResultActionsMobile = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { selectedProductResult, selectedProduct, products } = useSelector((state: FullState) => state.productsCatalog)
  const { selectedWallsIds, isFloorSelected, isWallsSelected, isRugSelected, visualizeData } = useSelector((state: FullState) => state.surfaces)
  const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
  const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
  const { isOnCompareResult, isLoading, drawerHeight } = useSelector((state: FullState) => state.generals)

  const [isRotateSelected, setIsRotateSelected] = useState(false)

  const [isVariantsModalOpen, setIsVariantsModalOpen] = useState(false)

  const handleRemoveProduct = () => {
    //searchParams.set('productId', product.id)
    dispatch(setSelectedProduct(undefined))
    let data = visualizeData

    if (isWallsSelected) {
      selectedWallsIds.forEach(wall => {
        data = removeProductForSurface(data, wall, "walls")
      })
      dispatch(selectWalls())
    }

    if (isFloorSelected) {
      data = removeProductForSurface(data, 0, "floor")
      dispatch(selectFloor())
    }
    dispatch(setSurfaces(data, selectedSpace.id))
    runProcess(data)
  }

  const runProcess = (data) => {
    spaceCatalogService.processProduct(data, selectedSpace)
  }

  const removeProductForSurface = (data: VisualizeModel, surfaceId, surfaceType) => {
    const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
    let surfaces = data[surfaceType]
    if (existingSurface)
      surfaces = data[surfaceType].filter(surface => surface !== existingSurface)

    return {
      ...data,
      [surfaceType]: surfaces.filter(surface => surface.surfaceKey !== surfaceId)
    }
  }

  const handleRotateSurface = (degree) => {
    let data = visualizeData
    if (isFloorSelected) {
      data = rotateProductForSurface(data, degree, 0, "floor")
      dispatch(setSurfaces(data, selectedSpace.id))
      runProcess(data)
    } else if (isWallsSelected) {
      selectedWallsIds.forEach((wallId, index) => {
        data = rotateProductForSurface(data, degree, wallId, "walls")
        if (index === selectedWallsIds.length - 1) {
          dispatch(setSurfaces(data, selectedSpace.id))
          runProcess(data)
        }
      });
    }
  }

  const rotateProductForSurface = (data: VisualizeModel, degree, surfaceId, surfaceType) => {
    const newSurfaces = data[surfaceType].map(surface => {
      if (surface.surfaceKey === surfaceId) {
        const newOrientation = surface.orientation + degree === 360 ? 0 : surface.orientation + degree
        EventsService.push(new Event(EventTypes.ProductRotatedEvent, new ProductRotatedEvent(selectedProduct, selectedSpace, getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), newOrientation)))
        return {
          ...surface,
          orientation: newOrientation
        }
      } else {
        return surface
      }
    })
    return {
      ...data,
      [surfaceType]: newSurfaces
    }
  }

  const handleCompareClick = () => {
    if (!isOnCompareResult) {
      dispatch(setResultCompare(!isOnCompareResult))
      dispatch(setCloseCompare(false))
      dispatch(setDrawerHeight(closedDrawerHeight))
    }
    else {
      dispatch(setDrawerHeight(closedDrawerHeight))
      dispatch(setCloseCompare(true))
    }
  }


  const selectProductForSurface = (data: VisualizeModel, surfaceId, surfaceType, product: ProductModel, actualSpace: string) => {
    const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
    let surfaces = data[surfaceType]
    let orientation = 0
    if (existingSurface) {
      surfaces = data[surfaceType].filter(surface => surface !== existingSurface)
      orientation = existingSurface.orientation
    }

    return {
      ...data,
      [surfaceType]: [...surfaces, {
        orientation: orientation,
        productId: product.id,
        surfaceKey: surfaceId
      }]
    }
  }

  const handleProductSelect = async (product) => {
    if (!product) return;

    const selectedSurface = isFloorSelected
      ? "floor"
      : isWallsSelected
        ? "walls"
        : isRugSelected
          ? "interactive_floor"
          : "";

    EventsService.push(
      new Event(
        EventTypes.ProductUsageEvent,
        new ProductUsageEvent(product, selectedSpace, selectedSurface)
      )
    );

    dispatch(setPreselectedSKU(null));
    dispatch(setSelectedSKUForSurface(selectedSurface, product.sku));
    dispatch(setSelectedProduct(product));

    if (!isRugSelected) {
      let data = { ...visualizeData };

      if (isWallsSelected) {
        selectedWallsIds.forEach((wall) => {
          data = selectProductForSurface(data, wall, "walls", product, selectedSpace.id);
        });
      }

      if (isFloorSelected) {
        data = selectProductForSurface(data, 0, "floor", product, selectedSpace.id);
      }

      dispatch(setSurfaces(data, selectedSpace.id));
      setIsVariantsModalOpen(false);
      runProcess(data);
    } else {
      setIsVariantsModalOpen(false);
      dispatch(setSelectedProduct(product));
    }
  };

  const handleSiblingSelected = async (oldProduct, product) => {
    updateDisplayedProducts(oldProduct, product)
    handleProductSelect(product)
  }

  const updateDisplayedProducts = (selectedProduct, sibling) => {
    if (selectedProduct.id !== sibling.id) {
      const selectedProductIndex = products.findIndex(product => product.id === selectedProduct.id)
      const siblings = selectedProduct.siblings.map(e => {
        if (e.id === sibling.id) {
          return {
            _id: sibling._id,
            ...selectedProduct,
            isDefault: false,
            siblings: [selectedProduct, ...selectedProduct.siblings.filter(s => s.id !== e.id)]
          }
        }
        else {
          return {
            ...e,
            siblings: [selectedProduct, ...selectedProduct.siblings.filter(s => s.id !== e.id)]
          }
        }
      })

      let newMainProduct = { ...sibling }
      newMainProduct.siblings = siblings
      newMainProduct.isDefault = true
      const tempProducts = [...products]
      tempProducts[selectedProductIndex] = newMainProduct

      dispatch(setProducts(tempProducts))
    }
  }

  return (<>
    {drawerHeight <= defaultDrawerHeight && <Box alignItems={"center"} position={"absolute"} display="flex" justifyContent={"space-around"} color={"black"} paddingBottom={1} bgcolor={"white"} zIndex={10000000}
      bottom={0} margin={0} left={0} height={40}
      width={"100%"}
      onClick={() => { }} >
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
        sx={{ cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer' : "not-allowed" }}
        onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && handleRemoveProduct()}>
        {/* <Delete fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
        <i className="fad fa-trash-can" style={getFaIconsStyles()} ></i>
        <Typography variant="caption" letterSpacing={-0.5} fontWeight={"bold"} marginLeft={1} sx={{ color: "black" }}>{t("VISUALIZER.BUTTONS.REMOVE")}</Typography>
      </Box>

      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} position="relative"
        sx={{ cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer' : "not-allowed" }}
        onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && setIsRotateSelected(!isRotateSelected)}>
        {/* <Rotate90DegreesCcw fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
        <i className="fad fa-rotate-right" style={getFaIconsStyles()} ></i>
        <Typography variant="caption" letterSpacing={-0.5} fontWeight={"bold"} marginLeft={1} sx={{ color: "black" }}>{t("VISUALIZER.BUTTONS.ROTATE")}</Typography>
        {isRotateSelected && <Box
          onMouseEnter={() => { setIsRotateSelected(true) }}
          onMouseLeave={() => { setIsRotateSelected(false) }}
          display="flex"
          justifyContent="center"
          position="absolute"
          bottom={25}
        >
          {!isRugSelected && <Box display="flex" justifyContent={"center"} alignItems="center">
            <Box
              onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && handleRotateSurface(90)}
              sx={{
                backgroundColor: 'white', borderRadius: 2, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: isWallsSelected ? "8px" : 0,
                cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer' : "not-allowed", padding: 1, position: "relative"
              }}
            >
              <i className="fad fa-rotate-right fa-xl" style={{ ...getFaIconsStyles() }}></i>
              <Typography variant="caption" fontWeight={"bold"} marginLeft={1} sx={{
                color: "white", right: 2, position: "absolute", background: SettingsService.settings?.primaryColor, borderRadius: "50%", fontSize: "7pt", padding: 0.1, bottom: 2
              }}>90°</Typography>
            </Box>
          </Box>}
          {!isWallsSelected && !isRugSelected && <Box display="flex" justifyContent={"center"} alignItems="center">
            <Box
              onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && handleRotateSurface(45)}
              sx={{
                backgroundColor: 'white', borderRadius: 2, borderTopLeftRadius: 0,
                cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer' : "not-allowed", padding: 1, position: "relative"
              }}
            >
              <i className="fad fa-rotate-right fa-xl" style={{ ...getFaIconsStyles() }}></i>
              <Typography variant="caption" fontWeight={"bold"} marginLeft={1} sx={{
                color: "white", right: 2, position: "absolute", background: SettingsService.settings?.primaryColor, borderRadius: "50%", fontSize: "7pt", padding: 0.1, bottom: 2
              }}>45°</Typography>
            </Box>
          </Box>}
        </Box>}
      </Box>

      {selectedProduct && selectedProduct.siblings && selectedProduct.siblings.length > 0 &&
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
          sx={{ cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer' : "not-allowed" }}
          onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && setIsVariantsModalOpen(true)}>
          {/* <Rotate90DegreesCcw fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
          <i className="fad fa-swatchbook" style={getFaIconsStyles()} ></i>
          <Typography variant="caption" letterSpacing={-0.5} fontWeight={"bold"} marginLeft={1} sx={{ color: "black" }}>{t("VISUALIZER.BUTTONS.COLORS")}</Typography>
        </Box>}

      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
        sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer' : "not-allowed" }}
        onClick={() => ((selectedProductResult || isRugSelected) && !isLoading) && handleCompareClick()}>
        {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
        {!isOnCompareResult && <i className="fad fa-images" style={getFaIconsStyles()} ></i>}
        {isOnCompareResult && <i className="fad fa-close" style={getFaIconsStyles()} ></i>}
        <Typography variant="caption" letterSpacing={-0.5} fontWeight={"bold"} marginLeft={1} sx={{ color: "black" }}>{isOnCompareResult ? t("VISUALIZER.BUTTONS.CLOSE_COMPARE") : t("VISUALIZER.BUTTONS.COMPARE")}</Typography>
      </Box>

      <ResultActionsMobileVariantsDialog isOpen={isVariantsModalOpen} setIsOpen={setIsVariantsModalOpen} handleProductSelect={handleSiblingSelected} />
    </Box>}
  </>
  )
}