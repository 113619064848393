import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useParams } from "react-router-dom";
import { ARVisualizerService } from "../services/ar-visualizer.service";
import { useSelector } from "react-redux";
import { FullState } from "../../../redux/rootReducer";
import { SpaceCatalogService } from "../../Visualizer/services/space-catalog.service";
import { Box } from "@mui/material";
import LoadingImage from "../../../assets/loader.gif"
import CopyrightLabel from "../../Visualizer/components/Result/Common/CopyrightLabel";

declare interface HTMLModelViewerElement extends HTMLElement {
    src: string;
    alt?: string;
    arScale?: string;
    arModes?: string;
    canActivateAR: boolean;
    activateAR: () => void;
}

export function ARModelViewerAndroid() {
    const [ARModel, setARModel] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [userInteracted, setUserInteracted] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);
    const { productId } = useParams();
    const { selectedProduct } = useSelector((state: FullState) => state.productsCatalog);
    const [spaceCatalogService] = useState(new SpaceCatalogService());

    const getARModel = async () => {
        try {
            const response = await spaceCatalogService.getProductMetadata(productId ?? selectedProduct.id);
            setARModel(response.data.arModel);
        } catch (e) {
            console.error("Failed to load AR model:", e);
        }
    };

    useEffect(() => {
        if (isMobile && userInteracted) {
            getARModel();
        }
    }, [userInteracted]);

    useEffect(() => {
        if (userInteracted) {
            if ('xr' in navigator) {
                navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
                    console.log("🔥 isSessionSupported('immersive-ar') =", supported);
                });
            } else {
                console.warn("❌ navigator.xr not available");
            }
        }
    }, [userInteracted]);


    useEffect(() => {
        if (ARModel && isMobile && userInteracted) {
            initViewer();
        }
    }, [ARModel]);

    function initViewer() {
        setIsLoading(true);

        const modelViewer = document.createElement('model-viewer') as HTMLModelViewerElement;
        modelViewer.id = 'model-viewer';

        modelViewer.src = ARModel!;
        modelViewer.alt = '3D model';
        modelViewer.setAttribute('ar', '');
        modelViewer.setAttribute('ar-modes', 'webxr quick-look');
        modelViewer.setAttribute('ar-scale', 'fixed');
        modelViewer.setAttribute('interaction-policy', 'always');
        modelViewer.setAttribute('reveal', 'auto');
        modelViewer.setAttribute('loading', 'eager');
        modelViewer.setAttribute('style', 'width: 100%; height: 100vh; background: transparent;');

        const container = document.getElementById("container");
        if (container) container.appendChild(modelViewer);

        modelViewer.addEventListener('load', () => {
            console.log("✅ Model loaded. Checking AR capabilities...");
            setIsLoading(false);

            setTimeout(() => {
                const shadowRoot = modelViewer.shadowRoot;
                const arButton = shadowRoot?.querySelector("#default-ar-button") as HTMLElement;

                if (arButton) {
                    console.log("✅ AR Button Detected in Shadow DOM");

                    arButton.style.display = "block";
                    arButton.style.opacity = "1";
                    arButton.style.visibility = "visible";
                    arButton.style.pointerEvents = "auto";
                    arButton.style.zIndex = "9999";

                    arButton.addEventListener("click", () => {
                        console.log("🔍 AR Button Clicked");
                    });

                    if (modelViewer.canActivateAR) {
                        console.log("📱 WebXR AR Supported - Launching...");
                        modelViewer.activateAR();
                    } else {
                        console.warn("❌ canActivateAR is false - waiting for user interaction");
                    }
                } else {
                    console.warn("⚠️ AR Button Not Found in Shadow DOM");
                }
            }, 1000);
        });
    }

    const handleCloseTab = () => {
        window.close();
    };

    return (
        <Box bgcolor={"#00000090"}>
            {!userInteracted && (
                <div
                    onClick={() => setUserInteracted(true)}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        background: '#000',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                        cursor: 'pointer',
                        fontSize: '24px',
                        fontWeight: 'bold'
                    }}
                >
                    👆 Tap to start
                </div>
            )}

            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100vh"}
                width={"100vw"}
                style={{ visibility: isLoading ? "visible" : "hidden" }}
                flexDirection={"column"}
            >
                <Box width={"100px"} height={"100px"} display="flex" justifyContent={"center"} alignItems={"center"} borderRadius={2} marginTop={4}>
                    <img src={LoadingImage} width={"75px"} />
                </Box>
                <CopyrightLabel />
            </Box>

            <div id="container" style={{ visibility: !isLoading ? "visible" : "hidden" }}></div>

            {showCloseButton && (
                <button
                    onClick={handleCloseTab}
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        padding: "10px 20px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        cursor: "pointer"
                    }}
                >
                    Close Tab
                </button>
            )}
        </Box>
    );
}
