
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useParams } from "react-router-dom";
import { ARVisualizerService } from "../services/ar-visualizer.service";
import { useSelector } from "react-redux";
import { FullState } from "../../../redux/rootReducer";
import { SpaceCatalogService } from "../../Visualizer/services/space-catalog.service";
import { Box } from "@mui/material";
import LoadingImage from "../../../assets/loader.gif"
import CopyrightLabel from "../../Visualizer/components/Result/Common/CopyrightLabel";

export function ARModelViewer(props) {
    const [object3D, setObject3D] = useState(undefined)
    const [scene3D, setScene3D] = useState(undefined)
    const [arVisualizerService] = useState(new ARVisualizerService())
    const [spaceCatalogService] = useState(new SpaceCatalogService())
    const [ARModel, setARModel] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const { productId } = useParams()
    const { selectedProduct } = useSelector((state: FullState) => state.productsCatalog)
    const [hasEnteredAR, setHasEnteredAR] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    const getARModel = async () => {
        try {
            const response = await spaceCatalogService.getProductMetadata(productId ?? selectedProduct.id)
            setARModel(response.data.arModel)
            return response.data.arModel
        } catch (e) {
            setARModel(undefined)
        }
    }

    useEffect(() => {
        if (isMobile) {
            getARModel()
        }
    }, [])

    const run = async () => {
        const model = await getARModel()
        if (model)
            setTimeout(() => {
                hideARButtonAndTrigger()
            }, 1000);
    }

    useEffect(() => {
        if (isMobile && props.runId) {
            run()
        }
    }, [props.runId])


    useEffect(() => {
        if (ARModel && isMobile) {
            initViewer();
        }
    }, [ARModel])

    const isDeviceAndroid = /android/i.test(navigator.userAgent);

    function onLoadComplete(modelViewer) {
        // get scene3D and object3D references for us to directly manipulate ThreeJS scene
        setScene3D(modelViewer[
            Object.getOwnPropertySymbols(modelViewer).find(
                (x) => x.description === 'scene'
            )
        ])

        const tempObject3D = scene3D?.getObjectByName('Painting1');
        tempObject3D.visible = false;
        setObject3D(tempObject3D)
    }

    function handleWebXRStateChange(event) {
        console.log("AR Status Event Detected:", event.detail.status);

        switch (event.detail.status) {
            case 'not-presenting': // NOT PRESENTING //
                object3D.visible = false;
                if (hasEnteredAR) {
                    console.log("AR session exited. Attempting to close tab...");
                    tryCloseTab();
                }
                break;
            case 'session-started': // SEARCHING //
                console.log("✅ AR Mode Started");
                object3D.visible = false;
                setHasEnteredAR(true);
                break;
            case 'object-placed': // OBJECT PLACED //
                // show model
                object3D.visible = true;
                break;
            case 'failed': // FAILED //
                console.log('WEB XR FAILED');
                break;
            default:
                console.warn(`Unexpected WebXR Status: ${event.detail.status}`);
                break;
        }
    }

    const tryCloseTab = () => {
        if (window.opener) {
            window.close();
        } else {
            console.warn("Safari blocked tab closure. Showing manual close button.");
            setShowCloseButton(true);
        }
    };

    const handleCloseTab = () => {
        window.close();
    };

    function hideARButtonAndTrigger() {
        setTimeout(() => {
            const modelViewer = document.getElementById("model-viewer");
            const shadowRoot = modelViewer?.shadowRoot;
            const arButton = shadowRoot?.querySelector('.ar-button');
            setIsLoading(false)
            if (arButton) {
                console.log(arButton);
                arButton.dispatchEvent(new Event('click'));
            } else {
                console.error('AR button not found');
            }
        }, 5000); // Adjust timeout if needed
    }

    function initViewer() {
        setIsLoading(true)
        const modelViewer: any = document.createElement('model-viewer');
        modelViewer.id = 'model-viewer';
        modelViewer.alt = 'op art';
        modelViewer.reveal = 'manual';
        modelViewer.touchAction = 'none';
        modelViewer.setAttribute('ar', '');
        modelViewer.arPlacement = "floor";
        modelViewer.arModes = 'webxr quick-look';
        modelViewer.arScale = 'fixed';
        modelViewer.shadowIntensity = "floor"
        modelViewer.shadowSoftness = 0;
        modelViewer.exposure = 1;
        modelViewer.touchAction = 'pan-y';
        modelViewer.opacity = "0"

        if (isDeviceAndroid) {
            modelViewer.addEventListener('ar-status', handleWebXRStateChange);
            modelViewer.addEventListener('preload', () => onLoadComplete(modelViewer));
            modelViewer.loading = 'eager';

            // Create the ar-prompt div and its child img and append to modelViewer
            const arPrompt = document.createElement('div');
            arPrompt.id = 'ar-prompt';
            const arPromptImg = document.createElement('img');
            arPromptImg.src = 'https://modelviewer.dev/assets/hand.png';
            arPrompt.appendChild(arPromptImg);
            modelViewer.appendChild(arPrompt);

            // Create the ar-failure button and append to modelViewer
            const arFailureButton = document.createElement('button');
            arFailureButton.id = 'ar-failure';
            arFailureButton.textContent = 'AR is not tracking!';
            modelViewer.appendChild(arFailureButton);
        } else {
            // 🍏 iOS FIX: Detect AR button click
            setTimeout(() => {
                const modelViewerElement = document.getElementById("model-viewer");
                const shadowRoot = modelViewerElement?.shadowRoot;
                const arButton = shadowRoot?.querySelector(".ar-button");

                if (arButton) {
                    console.log("✅ AR Button Detected - Attaching Click Event for iOS");
                    arButton.addEventListener("click", () => {
                        console.log("🔍 AR Mode Activated via Quick Look!");
                        setHasEnteredAR(true);
                    });
                } else {
                    console.warn("⚠️ AR Button Not Found in Shadow DOM");
                }
            }, 2000);
        }

        // set source
        modelViewer.src = ARModel
        document.getElementById("container").appendChild(modelViewer);
    }

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible" && hasEnteredAR) {
                console.log("🚪 User exited AR (Quick Look) - Closing tab...");
                tryCloseTab();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
    }, [hasEnteredAR]);


    useEffect(() => {
        if (document.getElementById('model-viewer') && isMobile)
            hideARButtonAndTrigger()
    }, [document.getElementById('container')?.innerHTML])

    return (<Box bgcolor={"#00000090"}>

        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100vh"} width={"100vw"} style={{ visibility: isLoading ? "visible" : "hidden" }} flexDirection={"column"}>
            <Box width={"100px"} height={"100px"} display="flex" justifyContent={"center"} alignItems={"center"} borderRadius={2} marginTop={4}>
                <img src={LoadingImage} width={"75px"} />
            </Box>
            <CopyrightLabel />
        </Box>
        <div id="container" style={{ visibility: !isLoading ? "visible" : "hidden" }}></div>
        {showCloseButton && (
            <button onClick={handleCloseTab} style={{ position: "fixed", bottom: "20px", right: "20px", padding: "10px 20px", backgroundColor: "red", color: "white", border: "none", cursor: "pointer" }}>
                Close Tab
            </button>
        )}
    </Box>
    );
}