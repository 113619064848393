import { Box, Typography } from "@mui/material";

export const ParamBadge = ({ iconClass, text }) => (
    <Box
        sx={{
            display: 'inline-block',
            backgroundColor: '#f0f0f0',
            borderRadius: '12px',
            padding: '2px 8px',
            color: '#757575',
            marginTop: '4px',
            marginRight: '4px',
        }}
    >
        <i className={iconClass} style={{ marginRight: '6px' }}></i>
        <Typography variant="body2" component="span" style={{ fontSize: 12 }}>
            {text}
        </Typography>
    </Box>
);