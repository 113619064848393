import { Box, ImageListItem, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setSelectedSpace } from "../../../../redux/space/spaceActions";
import { getFaIconsStyles } from "../../../../common/styles/styles";
import { EventsService } from "../../../../common/services/events.service";
import { Event, EventTypes, SpaceSelectedEvent } from "../../../../common/models/events/event.model";

/**
* Renders space's image
*/
export function SpaceItem({ space, index, newHeight, isUploadedByUser = false, deleteUploadedSpace = undefined }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [params] = useSearchParams();

  const goToVisualizer = () => {
    dispatch(setSelectedSpace(space))
    const productIdQuery = params.get("productId") ? `&productId=${params.get("productId")}` : ""
    const spaceIdQuery = `spaceId=${space.id}`
    EventsService.push(new Event(EventTypes.SpaceSelectedEvent, new SpaceSelectedEvent(space, index)))
    navigate({
      pathname: `${location.pathname}/playground`,
      //search: `?${spaceIdQuery}${productIdQuery}`,
    });
  }

  return (
    <ImageListItem key={space.img} onClick={goToVisualizer} sx={{ cursor: "pointer", position: "relative" }}>
      <img
        src={space.thumbnailPath}
        alt={space.title}
        loading="lazy"
        style={{
          borderRadius: "8px",
          objectFit: "cover",
          width: "100%",
          height: "auto", // This allows masonry to correctly determine height
        }}
      />
      {space.uploadedByVisitor && (
        <i
          className="fa-light fa-trash-can fa-lg"
          onClick={(event) => {
            event.stopPropagation(); // Prevent goToVisualizer from triggering
            deleteUploadedSpace(space);
          }}
          style={{
            ...getFaIconsStyles(),
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            zIndex: 70000,
            //@ts-ignore
            "--fa-primary-color": "white", "--fa-primary-color": "black",
            "--fa-primary-opacity": 1,
            "--fa-secondary-opacity": 1,
            backgroundColor: "white",
            borderRadius: 50,
            padding: 6,
          }}
        ></i>
      )}
      {isUploadedByUser && space.createdAt && (
        <Box
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            cursor: "pointer",
            zIndex: 70000,
            backgroundColor: "white",
            borderRadius: 50,
            padding: 2,
          }}
        >
          <Typography marginInline={2} variant="caption" textAlign={"center"}>
            {new Date(space.createdAt).toLocaleDateString()}
          </Typography>
        </Box>
      )}
    </ImageListItem>
  );
  
}