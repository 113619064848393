import { useDispatch, useSelector } from "react-redux";
import { Header } from "../modules/SpacePicker/components/Header/Header";
import { SpacesGallery } from "../modules/SpacePicker/layout/SpacesGallery";
import { useContext, useEffect, useState } from "react";
import { selectFloor, setSelectedWalls } from "../redux/surfaces/surfacesActions";
import { setFilter, setProducts, setSelectedProduct, setSelectedProductResult } from "../redux/product/productActions";
import { FullState } from "../redux/rootReducer";
import { setSelectedSpace } from "../redux/space/spaceActions";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Loader } from "../modules/Visualizer/components/Loader/LoaderWithMessage";
import { VendorThemeContext } from "../providers/VendorThemeProvider";

export function SpacePicker() {
    const dispatch = useDispatch()
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const { stageLoadingMessage } = useSelector((state: FullState) => state.generals);
    const { vendorAlias } = useContext(VendorThemeContext);
    const [fadeIn, setFadeIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setSelectedProductResult(undefined))
        dispatch(setFilter({}))
        dispatch(setProducts([]))
        if (!selectedSpace) {
            dispatch(setSelectedProduct(undefined))
            dispatch(setSelectedWalls([]))
            dispatch(selectFloor())
        } else {
            dispatch(setSelectedSpace(undefined))
        }
        setFadeIn(true);
    }, [])

    useEffect(() => {
        if (selectedSpace) {
            navigate(`/visualizer/${vendorAlias}/playground`, { state: { isLoading: true } });
        }
    }, [selectedSpace]);
    
    return (
        <Box>
            {stageLoadingMessage ? (
                <Loader message={stageLoadingMessage} fullScreen />
            ) : (
                <>
                    <Header />
                    <SpacesGallery />
                </>
            )}
        </Box>
    );
}