import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../modules/Visualizer/layout/SideBar";
import { FullState } from "../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { SpaceCatalogService } from "../modules/Visualizer/services/space-catalog.service";
import { setSelectedProduct, setSelectedProductResult } from "../redux/product/productActions";
import SpacePlayground from "../modules/Visualizer/layout/SpacePlayground";
import { selectFloor, selectRug, selectWalls, setSelectedWalls, setSurfaces } from "../redux/surfaces/surfacesActions";
import { SettingsService } from "../common/services/settings.service";
import * as _ from "lodash"
import { Event, EventTypes, VisualizerPageViewedEvent } from "../common/models/events/event.model";
import { EventsService } from "../common/services/events.service";
import { VendorThemeContext } from "../providers/VendorThemeProvider";
import { useLocation } from 'react-router-dom';
import { clearStageLoading, setStageLoading } from "../redux/general/generalActions";
import { Loader } from "../modules/Visualizer/components/Loader/LoaderWithMessage";
import { useTranslation } from "react-i18next";

const initialVisualizeData = {
    floor: [],
    ceiling: [],
    walls: []
}

export function SpaceVisualizer() {
    const theme = useTheme();
    const location = useLocation();
    const { vendorAlias } = useContext(VendorThemeContext);
    const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const { selectedProduct, products, selectedProductResult, filter, startingPage } = useSelector((state: FullState) => state.productsCatalog)
    const dispatch = useDispatch()
    const { visualizeData, isWallsSelected, isFloorSelected, isRugSelected } = useSelector((state: FullState) => state.surfaces)
    const [fadeIn, setFadeIn] = useState(false);
    const { stageLoadingMessage } = useSelector((state: FullState) => state.generals);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const productsRef = useRef(products);
    const selectedProductResultRef = useRef(selectedProductResult);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    useEffect(() => {
        productsRef.current = products;
        selectedProductResultRef.current = selectedProductResult;
    }, [products, selectedProductResult]);

    // TODO : Handle the case when API returns an empty list of products
    useEffect(() => {
        if (!selectedSpace) {
            navigate(`/visualizer/${vendorAlias}`);
            return;
        }

        dispatch(setStageLoading(t('VISUALIZER.LABELS.EXPLORE_PRODUCTS')));

        const fetchAndApplyProduct = async () => {
            while (productsRef.current.length === 0) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }

            dispatch(setStageLoading(t('VISUALIZER.LABELS.APPLY_CHANGES')));

            while (!selectedProductResultRef.current) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }

            dispatch(setStageLoading(t('VISUALIZER.LABELS.FINALIZATION')));

            setTimeout(() => {
                dispatch(clearStageLoading());
                setFadeIn(true);
            }, 500);
        };

        fetchAndApplyProduct();
    }, [selectedSpace]);

    useEffect(() => {
        if (products.length > 0 && !selectedProduct) {
            dispatch(setSelectedProduct(products[0]));
        }
    }, [products]);

    // SELECT FIRST PRODUCT WHILE SHARING
    useEffect(() => {
        if (products && location.state && location.state.isFromSharing) {
            const selectedProduct = visualizeData.floor[0] ?? visualizeData.walls[0]
            if (selectedProduct)
                dispatch(setSelectedProduct(products.find(product => product.id === selectedProduct.productId)))
        }
    }, [products])

    useEffect(() => {
        if (!selectedSpace) {
            if (vendorAlias)
                navigate(`/visualizer/${vendorAlias}`)
            else navigate(`/visualizer`)
        } else {
            setFadeIn(true);
            const selectedSurface = isFloorSelected ? "floor" : isWallsSelected ? "walls" : isRugSelected ? "interactive_floor" : ""
            EventsService.push(new Event(EventTypes.VisualizerPageViewedEvent, new VisualizerPageViewedEvent(selectedSpace, visualizeData, selectedSurface)))
        }
    }, [])


    // useEffect(() => {
    //     if ((isOnIframe || isOnWebView) && selectedProduct && selectedSpace && !isRugSelected && products.length > 0) {
    //         const currentSpace = { ...selectedSpace }
    //         let data = initialVisualizeData
    //         const isAFloor = selectedProduct.application_types.find(e => e === "floor") && SettingsService.vendorHasWalls()
    //         const isAWall = selectedProduct.application_types.find(e => e === "walls") && SettingsService.vendorHasWalls()
    //         if (isAFloor) {
    //             dispatch(selectFloor())
    //             data.floor = [...data.floor, ...currentSpace.surfaceMarkers.floor.map(floor => ({
    //                 orientation: 0,
    //                 productId: selectedProduct.id,
    //                 surfaceKey: floor.id
    //             }))]
    //         }
    //         else if (isAWall) {
    //             dispatch(selectWalls())
    //             dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
    //             data.walls = [...data.walls, ...currentSpace.surfaceMarkers.walls.map(wall => ({
    //                 orientation: 0,
    //                 productId: selectedProduct.id,
    //                 surfaceKey: wall.id
    //             }))]
    //         }
    //         dispatch(setSurfaces(data, selectedSpace.id))
    //         runProcess(data)
    //     }
    // }, [products])

    useEffect(() => {
        if (isRugSelected && selectedSpace && products.length > 0) {
            selectDefaultProductForRugs()
        }
    }, [isRugSelected, selectedSpace, products])

    useEffect(() => {
        if (
            products.length > 0 &&
            selectedSpace &&
            selectedProduct &&
            !isRugSelected &&
            !selectedProductResult
        ) {
            const data = {
                floor: [],
                ceiling: [],
                walls: [],
            };

            if (
                selectedProduct.application_types.includes("floor") &&
                selectedSpace.surfaceMarkers.floor
            ) {
                dispatch(selectFloor());
                data.floor = selectedSpace.surfaceMarkers.floor.map(floor => ({
                    orientation: 0,
                    productId: selectedProduct.id,
                    surfaceKey: floor.id,
                }));
            } else if (
                selectedProduct.application_types.includes("walls") &&
                selectedSpace.surfaceMarkers.walls
            ) {
                dispatch(selectWalls());
                dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(w => w.id)));
                data.walls = selectedSpace.surfaceMarkers.walls.map(wall => ({
                    orientation: 0,
                    productId: selectedProduct.id,
                    surfaceKey: wall.id,
                }));
            }

            setTimeout(() => {
                dispatch(setSurfaces(data, selectedSpace.id));
                runProcess(data);
            }, 500);
        }
    }, [selectedProduct]); // 👈 critical change


    useEffect(() => {
        if (SettingsService.vendorHasFloor()) {
            dispatch(selectFloor())
        } else if (SettingsService.vendorHasWalls()) {
            dispatch(selectWalls())
        } else if (SettingsService.vendorHasRugs()) {
            dispatch(selectRug())
        }
    }, [])

    /** This fix height for all devices including Safari on mobile */
    useEffect(() => {
        const setVH = () => {
            const vh = window.visualViewport?.height ?? window.innerHeight;
            document.documentElement.style.setProperty('--vh', `${vh * 0.01}px`);
        };
        setVH();
        window.visualViewport?.addEventListener('resize', setVH);
        return () => {
            window.visualViewport?.removeEventListener('resize', setVH);
        };
    }, []);

    const selectLastSelectedProductsForWalls = () => {
        const wallsSelection = [];

        if (visualizeData.walls.length > 0 && selectedSpace.surfaceMarkers.walls) {
            dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
            selectedSpace.surfaceMarkers.walls.forEach(wall => {
                wallsSelection.push({
                    orientation: visualizeData.walls[0].orientation,
                    productId: visualizeData.walls[0].productId,
                    surfaceKey: wall.id
                })
            })
        }

        return wallsSelection
    }

    const selectLastSelectedProductsForFloors = () => {
        const floorSelection = [];

        if (visualizeData.floor.length > 0 && selectedSpace.surfaceMarkers.floor) {
            selectedSpace.surfaceMarkers.floor.forEach(floor => {
                floorSelection.push({
                    orientation: visualizeData.floor[0].orientation,
                    productId: visualizeData.floor[0].productId,
                    surfaceKey: floor.id
                })
            })
        }
        return floorSelection
    }

    const selectDefaultProductForFloors = () => {
        const floorSelection = [];
        const defaultProduct = products.filter(product => product.isDefault).filter(product => product.application_types.find(at => at === "floor"))[0]
        if (defaultProduct) {
            selectedSpace.surfaceMarkers.floor.forEach(floor => {
                floorSelection.push({
                    orientation: 0,
                    productId: defaultProduct.id,
                    surfaceKey: floor.id
                })
            })
            dispatch(setSelectedProduct(defaultProduct))
        }

        return floorSelection
    }

    const selectDefaultProductForRugs = () => {
        const rugSelection = [];
        const defaultProduct = products.filter(product => product.application_types.find(at => at === "interactive_floor"))[0]
        dispatch(setSelectedProduct(defaultProduct))
    }

    const selectDefaultProductForWalls = () => {
        const wallsSelection = [];
        const defaultProduct = products.filter(product => product.isDefault).filter(product => product.application_types.find(at => at === "walls"))[0]

        if (visualizeData.walls.length === 0) {
            dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
            selectedSpace.surfaceMarkers.walls.forEach(wall => {
                wallsSelection.push({
                    orientation: 0,
                    productId: defaultProduct.id,
                    surfaceKey: wall.id
                })
            })
        }
        dispatch(setSelectedProduct(defaultProduct))
        return wallsSelection
    }

    const runProcess = (currentVisualizeData) => {
        if (selectedSpace && (currentVisualizeData.floor.length > 0 || currentVisualizeData.walls.length > 0 || currentVisualizeData.ceiling.length > 0))
            spaceCatalogService.processProduct(currentVisualizeData, selectedSpace)
        else if (selectedSpace && (currentVisualizeData.floor.length === 0 && currentVisualizeData.walls.length === 0 && currentVisualizeData.ceiling.length === 0)) {
            dispatch(setSelectedProductResult(undefined))
            if (SettingsService.vendorHasFloor())
                dispatch(selectFloor())
            else if (SettingsService.vendorHasWalls()) {
                dispatch(selectWalls())
                dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
            }
            else if (SettingsService.vendorHasRugs())
                dispatch(selectRug())
        }

    }

    return (
        <>
            {stageLoadingMessage && (
                <Loader message={stageLoadingMessage} fullScreen />
            )}

            {selectedSpace && (
                <div
                    style={{
                        opacity: fadeIn ? 1 : 0,
                        transition: "opacity 2.5s ease",
                        display: selectedSpace ? "block" : "none",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: isMDandDown ? "column-reverse" : "row",
                            margin: 0,
                            position: "relative",
                            width: "100vw",
                            height: "100dvh", // ✅ Modern fix!
                            overflow: "hidden",
                            borderTop: "1px solid white",
                            flex: "auto",
                            background: "#f4f6fc",
                        }}
                    >
                        <SideBar />
                        <SpacePlayground />
                    </Box>

                </div>
            )}
        </>
    );
}

export function useIframeSafeHeight() {
    const getSafeHeight = () => document.documentElement.clientHeight;

    const [vh, setVh] = useState(getSafeHeight());

    useEffect(() => {
        const update = () => {
            setVh(document.documentElement.clientHeight);
        };

        update(); // initial load

        window.addEventListener("resize", update);
        window.addEventListener("orientationchange", update);
        window.addEventListener("pageshow", update);
        window.addEventListener("focus", update);

        return () => {
            window.removeEventListener("resize", update);
            window.removeEventListener("orientationchange", update);
            window.removeEventListener("pageshow", update);
            window.removeEventListener("focus", update);
        };
    }, []);

    return vh;
}