import { Box, CardMedia, Tooltip, Typography } from "@mui/material";
import * as _ from "lodash"
import "./WebProduct.scss"
import { Bookmark } from "../Common/Bookmark";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { EventsService } from "../../../../../common/services/events.service";
import { Event, EventTypes, ProductVisitVendorPageEvent } from "../../../../../common/models/events/event.model";
import { useSelector } from "react-redux";
import { FullState } from "../../../../../redux/rootReducer";
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer";
import { ProductDetailsModal } from "../ProductDetailsModal";
import { ParamBadge } from "../Common/ParamBadge";

const MAX_CHARS_FOR_PRODUCT_NAME = 50

export const WebProduct = (props) => {
    const { t } = useTranslation();
    const [containerLength, setContainerLength] = useState(1)
    const [productSiblings, setProductSiblings] = useState(
        props.item.siblings.length > 0 ?
            [...props.item.siblings, props.item].sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
            : []
    )
    const { isFloorSelected, isWallsSelected, isRugSelected } = useSelector((state: FullState) => state.surfaces)
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const [isSelected, setIsSelected] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (document.getElementsByClassName("product-list-web")[0]?.clientWidth)
            setContainerLength(document.getElementsByClassName("product-list-web")[0]?.clientWidth)

    }, [document.getElementsByClassName("product-list-web")[0]?.clientWidth])


    const handleGoToProductPage = (event) => {
        event.stopPropagation()
        EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent,
            new ProductVisitVendorPageEvent(
                props.item, selectedSpace, getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), props.key, true
            )
        ))
        window.open(props.item.link, '_blank');
    }

    const handleSiblingSelected = (event, oldProduct, sibling) => {
        event.stopPropagation()
        props.handleSiblingSelected(oldProduct, sibling)
    }

    useEffect(() => {
        let selected = props.item.id === props.selectedProduct?.id || productSiblings.findIndex(product => product.id === props.selectedProduct?.id) > -1
        setIsSelected(selected)
    }, [props.selectedProduct])

    return (
        <>{props.item && props.view == "web" &&

            <Box
                className="product-web"
                sx={{
                    border: isSelected ? 2 : 2,
                    borderColor: isSelected ? '#AFAFAF' : '#D7DBE695',
                    maxWidth: props.isShownAsList ? "100%" : `${containerLength / 3}px`,
                    marginBottom: props.isShownAsList ? 1 : 0.5,
                    padding: props.isShownAsList ? 0.85 : 0,
                    transition: "background-color 0.7s, border 0.7s",
                    "&:hover": {
                        backgroundColor: "#F9F9F9",
                        borderColor: '#AFAFAF'
                    },
                }}>
                <Box display={"flex"} onClick={() => props.handleProductSelect(props.item)} sx={{ cursor: "pointer" }}>
                    <Box sx={{ position: "relative" }}>
                        <img
                            style={{
                                width: props.isShownAsList ? "100px" : `${containerLength / 3 - 14}px`,
                                height: props.isShownAsList ? "100px" : `${containerLength / 3 - 14}px`,
                                padding: props.isShownAsList ? 0 : "2px",
                                borderRadius: "8px",
                                margin: props.isShownAsList ? 0.5 : 0
                            }}
                            src={props.item.thumbnailPath}
                        />
                        {props.showActions && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 1,
                                    right: 1,
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.handleBookmark(props.item);
                                }}
                            >
                                <Bookmark {...props} />
                            </Box>
                        )}
                    </Box>

                    {props.isShownAsList && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', position: "relative", width: "100%" }}>
                            <Box sx={{ flex: '1 0 auto', position: "relative", width: "90%", padding: 0, paddingTop: 0.15, paddingLeft: 1.4 }}>
                                <Typography color="gray" className="mb-1" style={{ fontSize: 12 }}>
                                    {props.item.brand}
                                </Typography>
                                <Tooltip title={props.item.name} arrow>
                                    <Typography color="blue-gray" className="mb-2 font-bold"
                                        style={{
                                            fontSize: 13,
                                            paddingRight: 8,
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitLineClamp: 2, /* number of lines to show */
                                            lineClamp: 2,
                                            WebkitBoxOrient: "vertical"
                                        }} >
                                        {props.item.name.length > MAX_CHARS_FOR_PRODUCT_NAME ? props.item.name.slice(0, MAX_CHARS_FOR_PRODUCT_NAME) + '...' : props.item.name}
                                    </Typography>
                                </Tooltip>
                                {(!productSiblings || productSiblings.length === 0) && <Typography color="gray" style={{ fontSize: 12, marginTop: 4 }}>
                                    {props.item.width}{props.item.unit} x {props.item.length}{props.item.unit}
                                </Typography>}
                                {productSiblings?.length > 0 &&
                                    <Box
                                        sx={{
                                            display: "inline-block",
                                            backgroundColor: "#f0f0f0",
                                            borderRadius: "12px",
                                            padding: "2px 8px",
                                            color: "#757575",
                                            marginTop: "4px"
                                        }}
                                    >
                                        <Typography variant="caption" component="span" style={{ fontSize: 12 }}>
                                            {productSiblings?.length} {t('VISUALIZER.LABELS.VARIANTS')}
                                        </Typography>
                                    </Box>
                                }
                            </Box>

                        </Box>
                    )}
                </Box>
                {isSelected && props.isShownAsList && productSiblings?.length > 0 &&
                    <Box>
                        <Box sx={{ fontSize: 12, marginTop: 1 }}>
                            <ParamBadge iconClass="fad fa-light fa-ruler" text={`${props.item.width}${props.item.unit} x ${props.item.length}${props.item.unit}`} />
                            <ParamBadge iconClass="fad fa-palette" text={`${props.item.color}`} />
                            <ParamBadge
                                iconClass="fad fa-thin fa-block-brick"
                                text={`${t(`VISUALIZER.PATTERNS.${props.item.pattern.toUpperCase().replace(' ', '_')}`)}`}
                            />
                        </Box>
                        <Box marginTop={2} >
                            <Box display={"flex"} flexWrap={"wrap"} marginTop={0}>
                                {productSiblings.map((sibling, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        sx={{
                                            width: "40px",
                                            height: "40px",
                                            padding: "2px",
                                            borderRadius: 1,
                                            cursor: "pointer",
                                            border: props.selectedProduct?.id === sibling.id ? "1px solid #AFAFAF" : "1px solid transparent",
                                            marginRight: 0.5
                                        }}
                                        image={sibling.thumbnailPath}
                                        onClick={(e) => handleSiblingSelected(e, props.item, sibling)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>}
                {props.isShownAsList && props.showActions && (
                    <Box>
                        {isSelected && (
                            <Box
                                sx={{
                                    borderBottom: "1px solid #D7DBE695",
                                    marginTop: 2,
                                    marginBottom: 1,
                                }}
                            />
                        )}
                        <Box
                            display={"flex"}
                            justifyContent={isSelected ? "center" : "flex-end"}
                            marginTop={isSelected ? 2 : -2}
                            marginBottom={isSelected ? 1 : 0}
                        >
                            <Typography
                                variant="caption"
                                style={{ fontSize: 12, color: "#7B8297", zIndex: "10000" }}
                                sx={{
                                    position: "relative",
                                    cursor: "pointer",
                                    "&:after": {
                                        content: '""',
                                        position: "absolute",
                                        left: 0,
                                        bottom: -0.8,
                                        width: "0%",
                                        height: "1px",
                                        backgroundColor: "#7B8297",
                                        transition: "width 0.3s ease-in-out",
                                    },
                                    "&:hover:after": {
                                        width: "100%",
                                    },
                                }}
                                onClick={handleOpenModal}>
                                {t('VISUALIZER.BUTTONS.MORE_DETAILS')}
                                <i className="fas fa-arrow-right" style={{ marginLeft: 5 }} />
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        }
            <ProductDetailsModal
                open={modalOpen}
                handleClose={handleCloseModal}
                product={props.item}
                handleProductSelect={props.handleProductSelect}
            />
        </>
    )
}