import { Box, Divider, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { toggleCatalogShownAsList } from "../../../../../redux/general/generalActions";
import { FullState } from "../../../../../redux/rootReducer";

import floorIcon from "../../../../../assets/visualizer/floor.png"
import wallsIcon from "../../../../../assets/visualizer/walls.png"
import rugIcon from "../../../../../assets/visualizer/rug.png"
import { getFaIconsStyles } from "../../../../../common/styles/styles";
import { selectFloor, selectRug, setSelectedWalls } from "../../../../../redux/surfaces/surfacesActions";
import { useTranslation } from "react-i18next";
import { SettingsService } from "../../../../../common/services/settings.service";
import { EventsService } from "../../../../../common/services/events.service";
import { Event, EventTypes, SurfaceTypeChangedEvent } from "../../../../../common/models/events/event.model";
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer";
import { shouldUseSelectedPageRef } from "../_SearchBar";

export const WebSearchBar = (props) => {
    const { isCatalogShownAsList } = useSelector((state: FullState) => state.generals)
    const { isFloorSelected, isWallsSelected, isRugSelected } = useSelector((state: FullState) => state.surfaces)
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [shouldResetResults, setShouldResetResults] = useState(false);

    const spaceHasFloorSurface = () => selectedSpace.surfaces.includes("floor")
    const spaceHasWallSurface = () => selectedSpace.surfaces.includes("walls")

    const allSurfaceAndProductConditionsAreMet = () => SettingsService.vendorHasFloor() && SettingsService.vendorHasWalls() && spaceHasFloorSurface() && spaceHasWallSurface()

    const checkIconStyle = () => {
        return {
            lineHeight: 1,
            "--fa-primary-color": "white", //SettingsService.settings?.primaryColor ?? "#2E41D3",
            "--fa-secondary-color": SettingsService.settings?.secondaryColor ?? "black",
            "--fa-primary-opacity": 1,
            "--fa-secondary-opacity": 1
        }
    }

    const handleSearchIconClick = () => {
        setIsSearchBarVisible(true);
    };

    const handleCloseSearchBar = () => {
        if (props.searchInputText.trim() === "") {
            setIsSearchBarVisible(false);
        } else {
            props.handleSearchProducts("");
            shouldUseSelectedPageRef.current = true;
            setShouldResetResults(true);
        }
    };

    useEffect(() => {
        if (shouldResetResults && props.searchInputText === "") {
            props.handleKeyDown("Enter");
            setShouldResetResults(false);
        }
    }, [props.searchInputText, shouldResetResults]);

    const commonButtonStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "35px",
        padding: "8px",
        backgroundColor: "white",
        borderRadius: 2,
        border: "1px solid #D7DBE6",
        cursor: "pointer",
        transition: "flex 0.2s ease-in-out",
    };

    return (
        <Box sx={{ padding: 1, background: "white", borderRadius: 4 }}>
            {allSurfaceAndProductConditionsAreMet() && (
                <>
                    <Grid container spacing={1} marginBottom={2}>
                        {SettingsService.vendorHasFloor() && <Grid item xs>
                            <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"row"} flex={1}
                                onClick={() => {
                                    if (!isFloorSelected) {
                                        shouldUseSelectedPageRef.current = true;
                                        dispatch(selectFloor())
                                        EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), "floor")))
                                    }
                                }}
                                sx={{
                                    backgroundColor: isFloorSelected ? "#f2f2f2" : "white",
                                    height: "45px",
                                    borderRadius: "8px",
                                    border: isFloorSelected ? "1px solid #b6b6b6" : "1px solid #d1d1d1",
                                    cursor: "pointer",
                                    transition: "background-color 0.6s", // Animation duration
                                    "&:hover": {
                                        backgroundColor: "#f2f2f2", // Adjusted color on hover
                                    },
                                }}>

                                {isFloorSelected ? (
                                    <i className="fad fa-solid fa-circle-check" style={{ ...checkIconStyle() }}></i>
                                ) : (
                                    <img src={floorIcon} width={"20px"} height={"20px"} />
                                )}

                                <Typography
                                    variant="caption"
                                    fontWeight={isFloorSelected ? "bold" : "normal"}
                                    color={isFloorSelected ? "black" : "#575757"}
                                    marginBlock={1}
                                    marginInline={1}
                                >
                                    {t('VISUALIZER.BUTTONS.FLOOR')}
                                </Typography>
                            </Box>
                        </Grid>}
                        {SettingsService.vendorHasWalls() && <Grid item xs>
                            <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"row"} flex={1} id="WallsSelector"
                                onClick={() => {
                                    if (!isWallsSelected && selectedSpace.surfaceMarkers.walls) {
                                        shouldUseSelectedPageRef.current = true;
                                        dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls?.map(e => e.id)))
                                        EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), "walls")))
                                    }
                                }}
                                sx={{
                                    backgroundColor: isWallsSelected ? "#f2f2f2" : "white",
                                    height: "45px",
                                    borderRadius: "8px",
                                    border: isWallsSelected ? "1px solid #b6b6b6" : "1px solid #d1d1d1",
                                    cursor: "pointer",
                                    transition: "background-color 0.6s", // Animation duration
                                    "&:hover": {
                                        backgroundColor: "#f2f2f2", // Adjusted color on hover
                                    },
                                }}>


                                {isWallsSelected ? (
                                    <i className="fad fa-solid fa-circle-check" style={{ ...checkIconStyle() }}></i>
                                ) : (
                                    <img src={wallsIcon} width={"20px"} height={"20px"} />
                                )}

                                <Typography
                                    variant="caption"
                                    fontWeight={isWallsSelected ? "bold" : "normal"}
                                    color={isWallsSelected ? "black" : "#575757"}
                                    marginBlock={1}
                                    marginInline={1}
                                >
                                    {t('VISUALIZER.BUTTONS.WALLS')}
                                </Typography>
                            </Box>
                        </Grid>}
                        {SettingsService.vendorHasRugs() && <Grid item xs>
                            <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"row"} flex={1}
                                onClick={() => {
                                    if (!isRugSelected) {
                                        dispatch(selectRug())
                                        EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), "rug")))
                                    }
                                }}
                                sx={{
                                    backgroundColor: isRugSelected ? "#CBD4E7" : "#CBD4E750",
                                    height: "45px",
                                    borderRadius: "8px",
                                    border: "1px solid #CBD4E7",
                                    cursor: "pointer",
                                    transition: "background-color 0.6s", // Animation duration
                                    "&:hover": {
                                        backgroundColor: "#CBD4E7", // Adjusted color on hover
                                    },
                                }}>
                                <img src={rugIcon} width={"20px"} height={"20px"} />
                                {<Typography variant="caption" fontWeight={"bold"} marginBlock={1} marginInline={1}>{t('VISUALIZER.BUTTONS.RUG')}</Typography>}
                            </Box>
                        </Grid>}
                    </Grid>
                    <Divider sx={{ marginBottom: 2 }} />
                </>
            )}

            {/* Buttons Row */}
            <Box
                sx={{
                    display: "flex",
                    marginBlock: allSurfaceAndProductConditionsAreMet() ? 1 : "0px 0.5rem",
                    alignItems: "center",
                    gap: 1,
                    transition: "all 0.6s ease-in-out",
                }}
            >
                {/* Search Icon Button */}
                <Box
                    sx={{
                        ...commonButtonStyles,
                        flex: isSearchBarVisible ? 0 : 0.5,
                        display: isSearchBarVisible ? "none" : "flex",
                    }}
                    onClick={handleSearchIconClick}
                >
                    <i className="fad fa-magnifying-glass fa-lg" style={getFaIconsStyles()}></i>
                </Box>

                {/* Filter Button */}
                <Box
                    sx={{
                        ...commonButtonStyles,
                        flex: isSearchBarVisible ? 3 : 2,
                    }}
                    onClick={props.handleOpenDialog}
                >
                    <i className="fad fa-filter-list fa-lg" style={getFaIconsStyles()}></i>
                    <Typography
                        variant="caption"
                        fontWeight={"bold"}
                        marginTop={1}
                        color="#7B8297"
                        margin={0}
                        marginLeft={1}
                    >
                        {t("VISUALIZER.BUTTONS.FILTERS.LABEL")}
                    </Typography>
                </Box>

                {/* Catalog List/Grid View Buttons */}
                <Box
                    sx={{
                        ...commonButtonStyles,
                        flex: 1,
                    }}
                >
                    <i
                        className="fad fa-list fa-lg"
                        style={{
                            ...getFaIconsStyles(),
                            cursor: "pointer",
                            backgroundColor: isCatalogShownAsList ? "whitesmoke" : "white",
                            borderRadius: 8,
                            padding: "8px",
                        }}
                        onClick={() => dispatch(toggleCatalogShownAsList(true))}
                    ></i>
                    <i
                        className="fad fa-grip-vertical fa-lg"
                        style={{
                            ...getFaIconsStyles(),
                            cursor: "pointer",
                            backgroundColor: !isCatalogShownAsList ? "whitesmoke" : "white",
                            borderRadius: 8,
                            padding: "8px",
                        }}
                        onClick={() => dispatch(toggleCatalogShownAsList(false))}
                    ></i>
                </Box>
            </Box>

            {/* Search Bar Section */}
            <Box
                sx={{
                    maxHeight: isSearchBarVisible ? "60px" : "0px",
                    overflow: "hidden",
                    transition: "max-height 0.6s ease-in-out, opacity 0.6s ease-in-out",
                    opacity: isSearchBarVisible ? 1 : 0,
                    marginTop: isSearchBarVisible ? 1 : 0,
                    marginBottom: isSearchBarVisible ? 2 : 0,
                }}
            >
                <OutlinedInput
                    size="small"
                    fullWidth
                    value={props.searchInputText}
                    onChange={(e) => props.handleSearchProducts(e.target.value)}
                    onKeyDown={(e) => props.handleKeyDown(e.key)}
                    type="text"
                    placeholder={t("VISUALIZER.LABELS.SEARCH_PLACEHOLDER")}
                    sx={{
                        marginTop: 1,
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={handleCloseSearchBar} edge="end">
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Box>
        </Box>
    );
};