import axios from "../interceptors/api-client";
import { pmsEndPoint } from '../../config/config.js'
import { Event, EventTypes } from "../models/events/event.model";

export class EventsService {
    static async push(event: Event) {
        const { performanceCookies = false, advertisingCookies = false } =
            JSON.parse(localStorage.getItem('cookieConsent')) || {};

        if (performanceCookies || (event.eventType === EventTypes.ProductResultDownloadedEvent && advertisingCookies)) {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: pmsEndPoint + '/events',
                data: event
            };

            await axios.request(config);
        }
    }
}