import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../../redux/product/productActions';
import { useSelector } from 'react-redux';
import { FullState } from '../../../../../redux/rootReducer';
import { SpaceCatalogService } from '../../../services/space-catalog.service';
import { Box } from '@mui/material';
import { getFaIconsStyles } from '../../../../../common/styles/styles';
import { SettingsService } from '../../../../../common/services/settings.service';
import { shouldUseSelectedPageRef } from '../../SideBar/_SearchBar';

const ScrollToBottom = ({ containerRef, view }) => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const dispatch = useDispatch()
  const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
  const {isFloorSelected, isWallsSelected, isRugSelected } = useSelector((state: FullState) => state.surfaces)
  const {products, nextPage, filter, startingPage} = useSelector((state: FullState) => state.productsCatalog)

  const [visitedPages, setVisitedPages] = useState([])
  const [isThereANextPage, setisThereANextPage] = useState(false)

  const checkScrollPosition = () => {
    const container = containerRef.current;
    if (container) {
      const isBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 1;
      setIsAtBottom(isBottom);
    }
  };

  useEffect(() => {
    setIsAtBottom(false)
    const container = containerRef.current;
    container.addEventListener('scroll', checkScrollPosition);

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  // const updateProducts = async() => {

  //   const selectedSurface = isFloorSelected ? "floor" : isWallsSelected ? "walls" : isRugSelected ? "interactive_floor" : ""

  //   const response = (await spaceCatalogService.fetchProducts({application_types: [selectedSurface], page: nextPage}));
  //   let pageProducts = response.data;
  //   const newNextPage = Number.parseInt(response.nextPage)
  //   dispatch(setProducts([...products, ...pageProducts], newNextPage));
  //   dispatch(toggleLoading())
  // }

  useEffect(() => {
    setVisitedPages([])
    setIsAtBottom(false)
    setisThereANextPage(false)
  }, [isFloorSelected, isWallsSelected, isRugSelected])

  useEffect(() => {
    if(isAtBottom){
      // If at the bottom, and we are not at the last page
      if(nextPage !== -1 && !visitedPages.find(page => page === nextPage - 1)){
        shouldUseSelectedPageRef.current = false;
        dispatch(setFilter({
          ...filter,
          page: nextPage
        }))
        setVisitedPages([...visitedPages, nextPage - 1])
        setisThereANextPage(true)
      }
      /* If at the bottom, 
       and we are at the last page 
       and there are some page that are not shown
       and we are not at the starting page
      */
      if(nextPage === -1 && startingPage > 1 && nextPage !== startingPage && !visitedPages.find(page => page === 1)){
        shouldUseSelectedPageRef.current = false;
        dispatch(setFilter({
          ...filter,
          page: 1
        }))
        setVisitedPages([...visitedPages, 1])
        setisThereANextPage(true)
      }
    } else {
      setisThereANextPage(false)
    }
  }, [isAtBottom]); 

  return <>
    {view !== "mobile" && <Box sx={{
      position: "absolute", 
      bottom: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "98%",
      backgroundColor: "white"
    }}>
      {isAtBottom && isThereANextPage && <Box
          width={"50px"}
          height={"50px"}
          zIndex={1}
      >
          <i className="fa-duotone fa-solid fa-spinner-third fa-2xl"
              style={{
                  ...getFaIconsStyles(),
                  //@ts-ignore
                  "--fa-primary-color": SettingsService.settings.primaryColor,
                  "--fa-secondary-color": SettingsService.settings.secondaryColor,
                  fontSize: "40px",
                  animation: "fa-spin 0.7s infinite linear" // Inline animation for faster spin
              }}>
          </i>
      </Box>}
    </Box>}
  </>;
};

export default ScrollToBottom;
