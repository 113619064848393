import { ProductModel } from "../../modules/Visualizer/models/product.model";
import { SET_PRODUCTS, SET_SELECTED_PRODUCT, SET_FLOORS_STARTING_PAGE, SET_SELECTED_WALLS_PRODUCT, SET_SELECTED_FLOORS_PRODUCT, SET_WALLS_STARTING_PAGE, SET_STARTING_PAGE, SET_FILTER, SET_SELECTED_PRODUCT_RESULT, SET_SELECTED_PRODUCT_RUG_RESULT } from "./productActionTypes";
import * as _ from "lodash"

export interface ProductsState {
    products: ProductModel[],
    nextPage: number,
    offset: number,
    limit: number,
    filter: any,
    startingPage: number,
    wallsStartingPage: number,
    floorsStartingPage: number,
    selectedProduct: ProductModel,
    selectedWallsProduct: ProductModel,
    selectedFloorsProduct: ProductModel,
    selectedProductResult: any,
    selectedProductRugResult: any,
    preselectedSKU: any,
    selectedSKUBySurface: {
        floor?: string;
        walls?: string;
    },
    selectedPageBySurface: {
        floor?: number;
        walls?: number;
    }
}

const initialState: ProductsState = {
    products: [],
    nextPage: 1,
    offset: 0,
    limit: 15, 
    startingPage: 1,
    wallsStartingPage: 1,
    floorsStartingPage: 1,
    filter: {},
    selectedProduct: undefined,
    selectedWallsProduct: undefined,
    selectedFloorsProduct: undefined,
    selectedProductResult: undefined,
    selectedProductRugResult: undefined,
    preselectedSKU: undefined,
    selectedSKUBySurface: {},
    selectedPageBySurface: {}
};

const productReducer = (state = initialState, action): ProductsState => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                nextPage: action.payload.nextPage,
                offset: action.payload.offset ?? state.offset,
                limit: action.payload.limit ?? state.limit
            }
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload
            }
        case SET_SELECTED_WALLS_PRODUCT:
            return {
                ...state,
                selectedWallsProduct: action.payload
            }
        case SET_SELECTED_FLOORS_PRODUCT:
            return {
                ...state,
                selectedFloorsProduct: action.payload
            }
        case SET_STARTING_PAGE:
            return {
                ...state,
                startingPage: action.payload
            }
        case SET_FLOORS_STARTING_PAGE:
            return {
                ...state,
                floorsStartingPage: action.payload
            }
        case SET_WALLS_STARTING_PAGE:
            return {
                ...state,
                wallsStartingPage: action.payload
            }
        case SET_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case SET_SELECTED_PRODUCT_RESULT:
            return {
                ...state,
                selectedProductResult: action.payload
            }
        case SET_SELECTED_PRODUCT_RUG_RESULT:
            return {
                ...state,
                selectedProductRugResult: action.payload
            }
        case "SET_SELECTED_SKU_FOR_SURFACE":
            return {
                ...state,
                selectedSKUBySurface: {
                    ...state.selectedSKUBySurface,
                    [action.payload.surface]: action.payload.sku
                }
            }
        case "SET_SELECTED_PAGE_FOR_SURFACE":
            return {
                ...state,
                selectedPageBySurface: {
                    ...state.selectedPageBySurface,
                    [action.payload.surface]: action.payload.page
                }
            }
        case "SET_PRESELECTED_SKU":
            return { ...state, preselectedSKU: action.payload }
        default:
            return state
    }
};

export default productReducer;