import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SpacePicker } from './pages/space-picker.page';
import { SpaceVisualizer } from './pages/space-visualizer.page';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { SettingsService } from "./common/services/settings.service";

import './all.scss'
import './App.scss'
import { IFrameContext, IframeProvider } from './providers/IFrameContext';
import { ARVisualizer } from './pages/ar-visualizer.page';
import { UploadSpace } from './pages/upload-space.page';
import { SpaceShare } from './pages/space-share.page';
import { VendorThemeProvider } from './providers/VendorThemeProvider';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import CookieConsentModal from './common/components/CookiesConsentModal';
import { WebViewContext, WebViewProvider } from './providers/WebViewContext';
import { CloseOutlined } from '@mui/icons-material';
import { UIProvider, useUIContext } from './providers/UIContext';
/** Import main fonts */
//import './common/fonts/fonts.js'

function App() {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const theme = useTheme()
  const isMDandUp = useMediaQuery(theme.breakpoints.up('md'));
  const isOnIframe = useContext(IFrameContext);
  const isOnWebView = useContext(WebViewContext);
  const [hasIframeConsent, setHasIframeConsent] = useState(true)
  const [windowParent, setWindowParent] = useState(window.parent)

  useEffect(() => {
    // Prevent pinch-to-zoom globally
    const handleGlobalTouchMove = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchmove', handleGlobalTouchMove, { passive: false });

    // Re-enable zoom for a specific div by its ID
    const allowZoomOnDiv = (event) => {
      const zoomableDiv = document.getElementById('TransformWrapper'); // Replace 'zoomableDivId' with the actual ID

      if (zoomableDiv && zoomableDiv.contains(event.target)) {
        // Allow zoom on this div (do nothing)
        alert("HERE")
      } else {
        // Prevent zoom elsewhere
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      }
    };

    document.addEventListener('touchmove', allowZoomOnDiv, { passive: false });

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('touchmove', handleGlobalTouchMove);
      document.removeEventListener('touchmove', allowZoomOnDiv);
    };
  }, []);

  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent');

    if (!storedConsent) {
      setHasIframeConsent(false);
    }

    if (isOnIframe || isOnWebView) {
      //@ts-ignore
      setWindowParent(isOnWebView ? window.ReactNativeWebView : window.parent);
      windowParent.postMessage(JSON.stringify({
        type: "GET_CONSENT_LOCAL_STORAGE"
      }), "*");

      const getConsents = async () => {
        window.addEventListener('message', async function (event) {
          if (event.data.type === "CONSENT_LOCAL_STORAGE") {
            if (event.data.value) {
              localStorage.setItem(event.data.key, event.data.value);
            }
            else {
              setHasIframeConsent(false)
            }
          }
        });
      }
      getConsents()
    }
  }, [isOnIframe, isOnWebView]);

  const handleAcceptAll = () => {
    const consentPreferences = {
      strictlyNecessary: true,
      performanceCookies: true,
      advertisingCookies: true,
      personalizationCookies: true,
    };
    if (isOnIframe || isOnWebView) {
      //@ts-ignore
      windowParent.postMessage(JSON.stringify({
        type: "FRAME_CONSENT",
        value: consentPreferences
      }), "*");
    }
    localStorage.setItem('cookieConsent', JSON.stringify(consentPreferences));
    setHasIframeConsent(true);
  };

  const handleDecline = () => {
    const consentPreferences = {
      strictlyNecessary: false,
      performanceCookies: false,
      advertisingCookies: false,
      personalizationCookies: false,
    }
    if (isOnIframe || isOnWebView) {
      //@ts-ignore
      windowParent.postMessage(JSON.stringify({
        type: "FRAME_CONSENT",
        value: consentPreferences
      }), "*");
    }
    localStorage.setItem('cookieConsent', JSON.stringify(consentPreferences));
  };

  const handleCustomize = () => {
    setOpen(true)
  };

  const onClose = (accepted = false) => {
    if (accepted) {
      setHasIframeConsent(true);
    }
    setOpen(false);
  };

  const handleCloseIframe = () => {
    //@ts-ignore
    windowParent.postMessage(JSON.stringify(
      {
        type: "FRAME_CLOSED"
      }),
      "*"
    );
  }

  return (
    <div dir={i18n?.language === "ar" ? "rtl" : "ltr"}>
      <CookieConsentModal open={open} onClose={onClose} />

      {!hasIframeConsent &&
        <CookieConsent
          location="bottom"
          buttonText={null}
          style={{
            zIndex: '99999999',
            background: "#fff",
            borderRadius: "12px 12px 12px 12px",
            boxShadow: "0px 4px 18px rgba(0,0,0,0.15)",
            width: isMDandUp ? "30vw" : "90vw",
            margin: 16,
            padding: 0,
            paddingBottom: 50,
            minHeight: "auto",
          }}

          buttonStyle={{ display: "none" }}
          onAccept={handleAcceptAll}
        >
          <Typography variant="h6" sx={{ color: "#666", paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
            {t("CONSENT.TITLE")}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2, marginTop: 3, color: "grey.500", padding: "0 16px" }}>
            {t("CONSENT.FIRST_CAPTION")}
          </Typography>

          {/* Buttons Container - Fixed at Bottom */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: 0,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              borderTop: "1px solid #ddd",
            }}
          >
            {/* Customize Button */}
            <Box
              onClick={handleCustomize}
              sx={{
                cursor: "pointer",
                background: "#fff",
                color: "#333",
                borderRight: "1px solid #ddd",
                flex: 1,
                padding: "12px 0",
                borderRadius: "0px 0px 0px 12px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "all 0.2s ease-in-out",
                "&:hover": { background: "#f1f1f1" },
              }}
            >
              <Typography>{t("CONSENT.BUTTON_LABEL")}</Typography>
            </Box>

            {/* Accept All Button */}
            <Box
              onClick={handleAcceptAll}
              sx={{
                cursor: "pointer",
                background: SettingsService.settings?.primaryColor ?? "primary.main",
                color: "#fff",
                flex: 1,
                padding: "12px 0",
                borderRadius: "0px 0px 12px 0px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "all 0.2s ease-in-out",
                "&:hover": { opacity: 0.9 },
              }}
            >
              <Typography>{t("CONSENT.ACCEPT_ALL")}</Typography>
            </Box>
          </Box>
        </CookieConsent>
      }

      <UIProvider>
        <IframeProvider>
          <WebViewProvider>
            <BrowserRouter>
              <div className="App">
                <CloseButton handleCloseIframe={handleCloseIframe} />

                <Routes>
                  <Route path="/visualizer" element={<VendorThemeProvider><SpacePicker /></VendorThemeProvider>} />
                  <Route path="/visualizer/:vendorAlias" element={<VendorThemeProvider><SpacePicker /></VendorThemeProvider>} />
                  <Route path="/visualizer/:vendorAlias/playground" element={<VendorThemeProvider><SpaceVisualizer /></VendorThemeProvider>} />
                  <Route path="/visualizer/playground" element={<VendorThemeProvider><SpaceVisualizer /></VendorThemeProvider>} />
                  <Route path="/ar/:vendorAlias/products/:productId" element={<ARVisualizer />} />
                  <Route path="/space-uploader/:vendorAlias" element={<VendorThemeProvider><UploadSpace /></VendorThemeProvider>} />
                  <Route path="/share/:vendorAlias/:sharingId" element={<VendorThemeProvider><SpaceShare /></VendorThemeProvider>} />
                </Routes>
              </div>
            </BrowserRouter>

          </WebViewProvider>
        </IframeProvider>
      </UIProvider>
    </div>
  );
}

const CloseButton = ({ handleCloseIframe }) => {
  const isOnIframe = useContext(IFrameContext);
  const isOnWebView = useContext(WebViewContext);
  const { isWebPoppingBarVisible, isLoaderVisible } = useUIContext();

  return (
    (isOnIframe || isOnWebView) && !(isWebPoppingBarVisible && !isLoaderVisible) && (
      <Box
        onClick={handleCloseIframe}
        sx={{
          position: "fixed",
          top: 24,
          left: 24,
          width: 48,
          height: 48,
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: '1px 2px 5px -1px #88888850',
          zIndex: 99999999999,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CloseOutlined sx={{ fontSize: 28, color: "black" }} />
      </Box>
    )
  );
};

export default App;