import { ProductModel } from "../models/product.model";
import * as _ from 'lodash'
import { setProducts, setSelectedProductResult } from "../../../redux/product/productActions";
import { useDispatch, useSelector } from "react-redux";
import { startHighResLoading, startLowResLoading, stopLoading, toggleLoading } from "../../../redux/general/generalActions";
import { pmsEndPoint } from '../../../config/config.js'
import { pyStudioEndPoint } from '../../../config/config.js'
import axios from "../../../common/interceptors/api-client";
import { FullState } from "../../../redux/rootReducer";

export class SpaceCatalogService {
  dispatch = useDispatch()
  filters: any
  static selectedSpaceId: string
  static selectedProductId: string
  static abortController

  currentProductId = null;

  constructor() {
    this.currentProductId = null;
  }

  static updateSelectedProductId(id) {
    SpaceCatalogService.abortController?.abort();
  }

  static updateSelectedSpaceId(id) {
    SpaceCatalogService.abortController?.abort();
  }

  async fetchProducts(filters?) {
    this.dispatch(toggleLoading());
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/products/search',
      data: {
        limit: filters?.limit ?? 15,
        page: filters?.page ?? 1, 
        ...filters
      }
    };

    const response = await axios.request(config)
    let products = response.data;
    this.dispatch(toggleLoading());
    return products
  }

  async getProductBySKU(productSKU, filters = {}) {
    const limit = (filters && 'limit' in filters) ? filters.limit : 15;

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/products/search',
      headers: {
        "X-Need-Result-Page": true
      },
      data: {
        ...filters,
        sku: productSKU,
        limit: limit,
      }
    };
  
    const response = await axios.request(config);
    return response.data;
  }

  async getProductsFilters(selectedSurface?) {
    const API_URL = `${pmsEndPoint}/products`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/filters${selectedSurface? `?surfaces=${selectedSurface}`:""}`
    };

    const response = await axios.request(config)
    return response.data
  }

  async processProduct(visualizeData, space) {
    // Cancel any ongoing requests before proceeding
    if (SpaceCatalogService.abortController) {
      SpaceCatalogService.abortController.abort();
    }

    // Create a new AbortController for the current request
    SpaceCatalogService.abortController = new AbortController();
    const signal = SpaceCatalogService.abortController.signal;

    // Set currentProductId to the new product being processed
    const currentProductId = space.id;
    this.currentProductId = currentProductId;

    try {
      const data = JSON.stringify(visualizeData);
      const fetchImage = async (quality = '') => {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${pyStudioEndPoint}/display/${space.id}${quality}`,
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
          data: data,
          signal: signal,
        };
        //@ts-ignore
        const response = await axios.request(config);
        return URL.createObjectURL(response.data);
      };

      this.dispatch(startLowResLoading());

      // Fetch the low-quality image first
      try {
        const imageUrlNoHQ = await fetchImage();
        // Only update state if the current product ID matches
        if (this.currentProductId === currentProductId) {
          this.dispatch(setSelectedProductResult(imageUrlNoHQ));
          this.dispatch(stopLoading());
          this.dispatch(startHighResLoading());
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return; // Exit early if the request was canceled
        } else {
          this.dispatch(stopLoading());
          return; // Exit early if an error occurred
        }
      }

      // Add a small delay before fetching the high-quality image
      await new Promise((resolve) => setTimeout(resolve, 200));

      // Fetch the high-quality image
      try {
        const imageUrlHQ = await fetchImage('?img_quality=high');
        // Only update state if the current product ID matches
        if (this.currentProductId === currentProductId) {
          this.dispatch(setSelectedProductResult(imageUrlHQ));
          this.dispatch(stopLoading());
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log('High-quality request canceled due to space change:', error.message);
        } else {
          this.dispatch(stopLoading());
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        this.dispatch(stopLoading());
      }
    }
  }

  async processRug(spaceId) {
    const API_URL = `${pyStudioEndPoint}/scene`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${spaceId}`
    };

    return await axios.request(config)
  }

  async processSpaceForRugs(spaceId) {
    this.dispatch(toggleLoading())

    const API_URL = `${pyStudioEndPoint}/scene`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${spaceId}`
    };

    const response = await axios.request(config)
    this.dispatch(toggleLoading())
    return response
  }

  async getProductMetadata(productId) {
    this.dispatch(toggleLoading())

    const API_URL = `${pmsEndPoint}/products`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${productId}`
    };

    const response = await axios.request(config)
    this.dispatch(toggleLoading())
    return response
  }

  async getShareId(space, visualizeData) {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/sharing',
      data: {
        space,
        visualizeData
      }
    };

    const response = await axios.request(config)
    return response.data
  }
}
