import { Box, Modal, Typography, Divider, Button, Collapse, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 600,
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflowY: 'auto'
};

export const ProductDetailsModal = ({ open, handleClose, product, handleProductSelect, isGridMode = false }) => {
    const { t } = useTranslation();
    const [featuresExpanded, setFeaturesExpanded] = useState(true);
    const [expanded, setExpanded] = useState(false);

    const toggleFeatures = () => {
        setFeaturesExpanded(!featuresExpanded);
    };

    const handleGoToProductPage = () => {
        if (product?.link) {
            window.open(product.link, '_blank');
        }
    };

    const handleApplyProduct = () => {
        if (product) {
            handleProductSelect(product);
            handleClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box display="flex" flexDirection="row">
                    <img
                        src={product?.thumbnailPath || ''}
                        alt={product?.name || ''}
                        style={{ width: '40%', height: '40%', borderRadius: '8px' }}
                    />

                    <Box display="flex" flexDirection="column" alignItems="flex-start" ml={2}>
                        <Typography id="modal-modal-title" variant="caption" mt={2}>
                            {product?.brand || ''}
                        </Typography>
                        <Typography id="modal-modal-title" variant="h6">
                            {product?.name || ''}
                        </Typography>

                        {/* Collapsible Description */}
                        <Typography
                            variant="caption"
                            color="text.secondary"
                            mt={0.5}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? t('VISUALIZER.BUTTONS.SHOW_LESS') : t('VISUALIZER.BUTTONS.SHOW_MORE')}
                        </Typography>

                        <Collapse in={expanded}>
                            <Typography variant="body2" color="text.secondary" mt={0.5}>
                                {product?.description || ''}
                            </Typography>
                        </Collapse>

                        {/* Apply Product Button */}
                        {!isGridMode && <Button
                            sx={{ marginTop: 2 }}
                            variant="outlined"
                            onClick={handleApplyProduct}
                        >
                            {t('VISUALIZER.BUTTONS.APPLY_PRODUCT')}
                        </Button>}
                    </Box>
                </Box>

                {/* Features Section */}
                <Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={toggleFeatures}
                    >
                        <Typography variant="h6" mt={2}>
                            {t('VISUALIZER.LABELS.FEATURES')}
                        </Typography>
                        <IconButton size="small">
                            {featuresExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>

                    {/* Collapsible Content */}
                    <Collapse in={featuresExpanded}>
                        <Box mt={2}>
                            {/* SKU Row */}
                            {product?.sku && (
                                <>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                        <Typography variant="body2"><strong>{t('VISUALIZER.LABELS.SKU')}:</strong></Typography>
                                        <Typography variant="body2">{product.sku}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}

                            {/* Collections Row */}
                            {product?.specifics?.collections?.length > 0 && (
                                <>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                        <Typography variant="body2"><strong>{t('VISUALIZER.LABELS.COLLECTION')}:</strong></Typography>
                                        <Typography variant="body2">{product.specifics.collections.join(', ')}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}

                            {/* Size Row */}
                            {product?.sizeName && (
                                <>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                        <Typography variant="body2"><strong>{t('VISUALIZER.LABELS.SIZE')}:</strong></Typography>
                                        <Typography variant="body2">{product.sizeName}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}

                            {/* Color Row */}
                            {product?.color && (
                                <>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                        <Typography variant="body2"><strong>{t('VISUALIZER.LABELS.COLOR')}:</strong></Typography>
                                        <Typography variant="body2">{product.color}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}

                            {/* Pattern Row */}
                            {product?.pattern && (
                                <>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                        <Typography variant="body2"><strong>{t('VISUALIZER.LABELS.PATTERN')}:</strong></Typography>
                                        <Typography variant="body2">
                                            {t(`VISUALIZER.PATTERNS.${product.pattern.toUpperCase().replace(' ', '_')}`)}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}
                        </Box>
                    </Collapse>
                </Box>

                {/* Buttons Section */}
                <Box mt={4} display="flex" justifyContent="space-between">
                    <Button
                        variant="outlined"
                        onClick={handleGoToProductPage}
                        sx={{ mr: 1 }}
                    >
                        {t('VISUALIZER.BUTTONS.GO_TO_PRODUCT_PAGE')}
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        {t('VISUALIZER.BUTTONS.CLOSE')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};