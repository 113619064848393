import { Box, Collapse, Tooltip, Typography } from "@mui/material"
import { getFaIconsStyles } from "../../../../../common/styles/styles"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { SettingsService } from "../../../../../common/services/settings.service"
import { VisualizeModel } from "../../../models/visualize.model"
import { Event, EventTypes, ProductCompareClickedEvent, ProductCompareClosedClickedEvent, ProductDeletedEvent, ProductRotatedEvent } from "../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer"
import { EventsService } from "../../../../../common/services/events.service"
import { selectFloor, selectWalls, setSurfaces } from "../../../../../redux/surfaces/surfacesActions"
import { useEffect, useState } from "react"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { setSelectedProduct } from "../../../../../redux/product/productActions"
import { setCloseCompare, setResultCompare } from "../../../../../redux/general/generalActions"
import { useTranslation } from "react-i18next"

export const RightPoppinBar = (props) => {
    const { t } = useTranslation();
    const { isLowResLoading, isOnCompareResult } = useSelector((state: FullState) => state.generals)
    const { selectedWallsIds, isFloorSelected, isWallsSelected, isRugSelected, visualizeData } = useSelector((state: FullState) => state.surfaces)
    const { selectedProductResult, selectedProduct } = useSelector((state: FullState) => state.productsCatalog)
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())

    const [isRotateSelected, setIsRotateSelected] = useState(false)

    const dispatch = useDispatch()

    const handleRotateSurface = (degree) => {
        let data = visualizeData
        if (isFloorSelected) {
            data = rotateProductForSurface(data, degree, 0, "floor")
            dispatch(setSurfaces(data, selectedSpace.id))
            runProcess(data)
        } else if (isWallsSelected) {
            selectedWallsIds.forEach((wallId, index) => {
                data = rotateProductForSurface(data, degree, wallId, "walls")
                if (index === selectedWallsIds.length - 1) {
                    dispatch(setSurfaces(data, selectedSpace.id))
                    runProcess(data)
                }
            });
        }
    }

    const rotateProductForSurface = (data: VisualizeModel, degree, surfaceId, surfaceType) => {
        const newSurfaces = data[surfaceType].map(surface => {
            if (surface.surfaceKey === surfaceId) {
                const newOrientation = surface.orientation + degree === 360 ? 0 : surface.orientation + degree
                EventsService.push(new Event(EventTypes.ProductRotatedEvent, new ProductRotatedEvent(selectedProduct, selectedSpace, getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), newOrientation)))
                return {
                    ...surface,
                    orientation: newOrientation
                }
            } else {
                return surface
            }
        })

        return {
            ...data,
            [surfaceType]: newSurfaces
        }
    }

    const handleRemoveProduct = () => {
        //searchParams.set('productId', product.id)
        dispatch(setSelectedProduct(undefined))
        let data = visualizeData

        if (isWallsSelected) {
            selectedWallsIds.forEach(wall => {
                data = removeProductForSurface(data, wall, "walls")
            })
            dispatch(selectWalls())
        }

        if (isFloorSelected) {
            data = removeProductForSurface(data, 0, "floor")
            dispatch(selectFloor())
        }
        dispatch(setSurfaces(data, selectedSpace.id))
        runProcess(data)
        if (selectedProduct)
            EventsService.push(new Event(EventTypes.ProductDeletedEvent, new ProductDeletedEvent(selectedProduct, selectedSpace, getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }))))
    }

    const removeProductForSurface = (data: VisualizeModel, surfaceId, surfaceType) => {
        const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
        let surfaces = data[surfaceType]
        if (existingSurface)
            surfaces = data[surfaceType].filter(surface => surface !== existingSurface)

        return {
            ...data,
            [surfaceType]: surfaces.filter(surface => surface.surfaceKey !== surfaceId)
        }
    }


    const handleCompareClick = () => {
        if (!isOnCompareResult) {
            EventsService.push(new Event(EventTypes.ProductCompareClickedEvent, new ProductCompareClickedEvent(visualizeData)))
            dispatch(setResultCompare(!isOnCompareResult))
            dispatch(setCloseCompare(false))
        }
        else {
            EventsService.push(new Event(EventTypes.ProductCompareClosedClickedEvent, new ProductCompareClosedClickedEvent()))
            dispatch(setCloseCompare(true))
        }
    }

    const runProcess = (data) => {
        spaceCatalogService.processProduct(data, selectedSpace)
    }

    const isSurfaceHavingAProduct = () => {
        const surfaceType = isFloorSelected ? "floor" : isWallsSelected ? "walls" : ""
        const surfaceIds = isFloorSelected ? [0] : selectedWallsIds
        return visualizeData[surfaceType].find(surface => surfaceIds.findIndex(surfaceId => surfaceId === surface.surfaceKey) > -1)
    }

    useEffect(() => {
        if (!((selectedProductResult && selectedProduct) || isRugSelected) || isLowResLoading) {
            setIsRotateSelected(false);
        }
    }, [selectedProductResult, selectedProduct, isRugSelected, isLowResLoading, isFloorSelected, isWallsSelected]);

    return (
        <Box
            position="absolute"
            bottom={60}
            right={20}
            zIndex={100000}
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{
                opacity: !props.areIconsVisible ? "0" : "1",
                visibility: !props.areIconsVisible ? "hidden" : "visible",
                transition: "opacity 0.3s, visibility 0.3s",
            }}
        >
            {/* Remove Product Button */}
            {!isRugSelected && isSurfaceHavingAProduct() && (
                <Tooltip title={t("VISUALIZER.BUTTONS.REMOVE_PRODUCT")} arrow placement="left">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: 2,
                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                                cursor: !isLowResLoading ? 'pointer' : 'not-allowed',
                                padding: 1,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backdropFilter: 'blur(4px)',
                                WebkitBackdropFilter: "blur(4px)",
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'white',
                                },
                            }}
                            onClick={isLowResLoading ? null : handleRemoveProduct}
                            >
                            <i className="fad fa-trash-can fa-xl" style={{ ...getFaIconsStyles(), marginLeft: 0 }}></i>
                        </Box>
                    </Box>
                </Tooltip>
            )}

            {/* Rotate Button */}
            {!isRugSelected && isSurfaceHavingAProduct() && (
                <Tooltip title={t("VISUALIZER.BUTTONS.ROTATE_SURFACE")} arrow placement="left">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box
                            onClick={isLowResLoading ? null : () => setIsRotateSelected(!isRotateSelected)}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: 2,
                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                                cursor: !isLowResLoading ? 'pointer' : 'not-allowed',
                                padding: 1,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backdropFilter: 'blur(4px)',
                                WebkitBackdropFilter: "blur(4px)",
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            <i className="fad fa-rotate-right fa-xl" style={{ ...getFaIconsStyles(), marginLeft: 0 }}></i>
                        </Box>
                    </Box>
                </Tooltip>
            )}

            {/* Rotate Options */}
            {isRotateSelected && !isRugSelected && isSurfaceHavingAProduct() && (
                <Collapse in={isRotateSelected} timeout={300} unmountOnExit>
                    <Box display="flex" gap={1} flexDirection="column" justifyContent="center">
                        {/* 90° */}
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={isLowResLoading ? null : () => handleRotateSurface(90)}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: 2,
                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                                cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLowResLoading ? 'pointer' : 'not-allowed',
                                padding: 1,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backdropFilter: 'blur(4px)',
                                WebkitBackdropFilter: "blur(4px)",
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            <Typography>
                                90°
                            </Typography>
                        </Box>

                        {/* 45° */}
                        {isFloorSelected && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                onClick={isLowResLoading ? null : () => handleRotateSurface(45)}
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    borderRadius: 2,
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                    cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLowResLoading ? 'pointer' : 'not-allowed',
                                    padding: 1,
                                    width: 30,
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backdropFilter: 'blur(4px)',
                                    WebkitBackdropFilter: "blur(4px)",
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                    },
                                }}
                            >
                                <Typography>
                                    45°
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Collapse>
            )}

            {/* Compare Button */}
            <Tooltip title={t("VISUALIZER.BUTTONS.COMPARE")} arrow placement="left">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: 2,
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            cursor: (selectedProductResult || isRugSelected) && !isLowResLoading ? 'pointer' : 'not-allowed',
                            padding: 1,
                            width: 30,
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backdropFilter: 'blur(4px)',
                            WebkitBackdropFilter: "blur(4px)",
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: 'white',
                            },
                        }}
                        onClick={() =>
                            !isLowResLoading && (selectedProductResult || isRugSelected) ? handleCompareClick() : null
                        }
                    >
                        {!isOnCompareResult ? (
                            <i className="fad fa-thin fa-arrows-from-line fa-xl" style={{...getFaIconsStyles(), transform: "rotate(90deg)" }}></i>
                        ) : (
                            <i className="fad fa-close fa-xl" style={getFaIconsStyles()}></i>
                        )}
                    </Box>
                </Box>
            </Tooltip>
        </Box>
    );
};