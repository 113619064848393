import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Switch, Grid, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { SettingsService } from "../services/settings.service"

const CookieConsentModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  const [strictlyNecessary, setStrictlyNecessary] = useState(true);
  const [performanceCookies, setPerformanceCookies] = useState(true);
  const [advertisingCookies, setAdvertisingCookies] = useState(true);
  const [personalizationCookies, setPersonalizationCookies] = useState(true);

  const handleSavePreferences = () => {
    const consentPreferences = {
      strictlyNecessary: true,
      performanceCookies: performanceCookies,
      advertisingCookies: advertisingCookies,
      personalizationCookies: personalizationCookies,
    };

    localStorage.setItem('cookieConsent', JSON.stringify(consentPreferences));
    onClose(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{ zIndex: 99999999 }}>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold" color="black">{t("CONSENT.SETTINGS_TITLE")}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          {t("CONSENT.DESCRIPTION")}
        </Typography>

        {/* Strictly Necessary Cookies */}
        <Accordion expanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>{t("CONSENT.STRICTLY_NECESSARY")}</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t("CONSENT.STRICTLY_NECESSARY_DESCRIPTION")}
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>{t("CONSENT.STRICTLY_NECESSARY_ENABLED")}</Typography>
              <Switch checked={true} disabled />
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Performance Cookies */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>{t("CONSENT.PERFORMANCE_COOKIES")}</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t("CONSENT.PERFORMANCE_COOKIES_DESCRIPTION")}</Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>{t("CONSENT.ENABLE_PERFORMANCE_COOKIES")}</Typography>
              <Switch
                checked={performanceCookies}
                onChange={(e) => setPerformanceCookies(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: SettingsService.settings?.primaryColor ?? "primary.main",
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: SettingsService.settings?.primaryColor ?? "primary.main",
                  },
                }}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Advertising Cookies */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>{t("CONSENT.ADVERTISING_COOKIES")}</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t("CONSENT.ADVERTISING_COOKIES_DESCRIPTION")}</Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>{t("CONSENT.ENABLE_ADVERTISING_COOKIES")}</Typography>
              <Switch
                checked={advertisingCookies}
                onChange={(e) => setAdvertisingCookies(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: SettingsService.settings?.primaryColor ?? "primary.main",
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: SettingsService.settings?.primaryColor ?? "primary.main",
                  },
                }}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Personalization Cookies */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>{t("CONSENT.PERSONALIZATION_COOKIES")}</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t("CONSENT.PERSONALIZATION_COOKIES_DESCRIPTION")}</Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>{t("CONSENT.ENABLE_PERSONALIZATION_COOKIES")}</Typography>
              <Switch
                checked={personalizationCookies}
                onChange={(e) => setPersonalizationCookies(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: SettingsService.settings?.primaryColor ?? "primary.main",
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: SettingsService.settings?.primaryColor ?? "primary.main",
                  },
                }}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Typography variant="body2" color="textSecondary" marginTop={1}>
          {t("CONSENT.LEARN_MORE")} <a href="https://ardealy.com/politique_des_cookies.html" target="_blank" rel="noopener noreferrer">{t("CONSENT.COOKIE_POLICY")}</a>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box onClick={() => onClose()} style={{ marginTop: "10px", cursor: "pointer", background: "white", width: "fit-content", padding: "8px 20px", borderRadius: 8 }}>
          <Typography variant="body2" fontWeight="bold" color="black">{t("CONSENT.CANCEL")}</Typography>
        </Box>
        <Box onClick={handleSavePreferences} style={{ marginTop: "10px", cursor: "pointer", background: `${SettingsService.settings?.primaryColor ?? "primary.main"}`, width: "fit-content", padding: "8px 20px", borderRadius: 8 }}>
          <Typography variant="body2" fontWeight="bold" style={{ color: `${SettingsService.settings?.textSecondaryColor ?? "white"}` }}>{t("CONSENT.ACCEPT")}</Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsentModal;
