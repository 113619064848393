import { Box, CardMedia, Divider, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { EventsService } from "../../../../../common/services/events.service"
import { Event, EventTypes, ProductVisitVendorPageEvent } from "../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer"
import { useEffect, useLayoutEffect, useState } from "react"
import * as _ from "lodash"
import { ParamBadge } from "../Common/ParamBadge"
import { ProductDetailsModal } from "../ProductDetailsModal"

export const WebProductDetails = ({ chunk, handleSiblingSelected }) => {
    const { t } = useTranslation()
    const { selectedProduct } = useSelector((state: FullState) => state.productsCatalog)
    const { isFloorSelected, isWallsSelected, isRugSelected } = useSelector((state: FullState) => state.surfaces)
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const [productSiblings, setProductSiblings] = useState(
        selectedProduct?.siblings.length > 0 ?
            [...selectedProduct?.siblings ?? [], selectedProduct].sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
            : []
    )
    const [displayedProduct, setDisplayedProduct] = useState<any>()
    const [selectedProductPosition, setSelectedProductPosition] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);

    const [chunksSiblings, setChunksSiblings] = useState(chunk.map(product => [...product.siblings, product]))

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const position = chunk.findIndex(product => product === selectedProduct) === -1 ?
                chunk.findIndex(product => product.id === displayedProduct?.id) :
                chunk.findIndex(product => product === selectedProduct)

        if (selectedProduct) {
            setProductSiblings(
                chunksSiblings[position]?.length > 1 ? chunksSiblings[position] : []
            )
            setDisplayedProduct(selectedProduct)
        }
    }, [selectedProduct])

    useEffect(() => {
        setSelectedProductPosition(getSelectedProductFromChunk(chunk))
    }, [displayedProduct])

    const getSelectedProductFromChunk = (chunk) => {
        if (chunk.find(product => product === selectedProduct) || chunk.find(product => product.id === displayedProduct?.id)) {
            const index = chunk.findIndex(product => product === selectedProduct) === -1 ?
                chunk.findIndex(product => product.id === displayedProduct.id) :
                chunk.findIndex(product => product === selectedProduct)

            return {
                product: chunk.find(product => product === selectedProduct) ?? chunk.find(product => product.id === displayedProduct?.id),
                index: index,
                position: (() => {
                    if (index === 0)
                        return "15%"
                    else if (index === 1)
                        return "50%"
                    else if (index === 2)
                        return "83%"
                })()
            }
        }
        else return null
    }

    const handleGoToProductPage = (event) => {
        event.stopPropagation()
        EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent,
            new ProductVisitVendorPageEvent(
                selectedProduct, selectedSpace, getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), chunk.findIndex(product => product === selectedProduct), true
            )
        ))
        window.open(selectedProduct.link, '_blank');
    }

    const handleSiblingSelectedAction = (event, oldProduct, sibling) => {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        handleSiblingSelected(oldProduct, sibling)
    }

    return (
        <>
            {displayedProduct && selectedProductPosition &&
                <Box width={"100%"}>
                    <Box sx={{ position: 'relative' }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                width: 0,
                                height: 0,
                                borderBottom: '10px solid #E7E7E7',
                                borderLeft: '10px solid transparent',
                                borderRight: '10px solid transparent',
                                top: '-9px', // Adjusted position
                                left: `${selectedProductPosition.position}`,
                                transform: 'translateX(-50%)',
                            }}
                        />
                        <Box paddingTop={2} paddingInline={2} paddingBottom={1}
                            sx={{
                                borderRadius: '5px',
                                border: "1px solid #E7E7E7",
                            }}>

                            <Typography variant="caption" color={"#7B8297"}>{displayedProduct.brand}</Typography>
                            <Typography variant="body2" >{displayedProduct.name}</Typography>
                            {(!productSiblings || productSiblings.length === 0) &&
                                <Typography variant="caption" color={"#7B8297"}>Size: {displayedProduct?.width}{displayedProduct?.unit} x {displayedProduct?.length}{selectedProduct?.unit}</Typography>
                            }

                            {productSiblings && productSiblings.length > 0 && (
                                <Box marginTop={1}>
                                    <ParamBadge iconClass="fad fa-palette" text={`${displayedProduct?.color}`} />
                                    <ParamBadge iconClass="fad fa-light fa-ruler" text={`${displayedProduct?.width}${displayedProduct?.unit} x ${displayedProduct.length}${displayedProduct?.unit}`} />
                                    <ParamBadge iconClass="fad fa-thin fa-block-brick" text={`${t(`VISUALIZER.PATTERNS.${displayedProduct.pattern.toUpperCase().replace(' ', '_')}`)}`} />
                                </Box>
                            )}
                            {productSiblings && productSiblings.length > 0 && <Box display={"flex"} flexWrap={"wrap"} marginBottom={2} marginTop={2}>

                                {productSiblings && productSiblings.map((sibling, index) => (
                                    <CardMedia
                                        component="img"
                                        key={index}
                                        sx={{
                                            width: "30px",
                                            height: "30px",
                                            padding: "2px",
                                            borderRadius: 1,
                                            border: selectedProduct && selectedProduct.id === sibling.id ? "1px solid #AFAFAF" : "1px solid transparent",
                                            cursor: "pointer",
                                            marginRight: 0.5
                                        }}
                                        image={sibling.thumbnailPath}
                                        onClick={(e) => handleSiblingSelectedAction(e, displayedProduct, sibling)}
                                    />
                                ))}
                            </Box>}
                            
                            <Divider sx={{ marginTop: 2 }} />

                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={2}
                                marginBottom={1}
                            >
                                <Typography
                                    variant="caption"
                                    style={{ fontSize: 12, color: "#7B8297", cursor: "pointer" }}
                                    sx={{
                                        position: "relative",
                                        "&:after": {
                                            content: '""',
                                            position: "absolute",
                                            left: 0,
                                            bottom: -0.8,
                                            width: "0%",
                                            height: "1px",
                                            backgroundColor: "#7B8297",
                                            transition: "width 0.3s ease-in-out",
                                        },
                                        "&:hover:after": {
                                            width: "100%",
                                        },
                                    }}
                                    onClick={handleOpenModal}>
                                    {t('VISUALIZER.BUTTONS.MORE_DETAILS')}
                                    <i className="fas fa-arrow-right" style={{ marginLeft: 5 }} />
                                </Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            }

            <ProductDetailsModal
                open={modalOpen}
                handleClose={handleCloseModal}
                product={displayedProduct}
                isGridMode={true}
                handleProductSelect={handleSiblingSelectedAction}
            />
        </>
    )
}