import { Checkbox, Accordion, Typography, AccordionSummary, AccordionDetails, Box, Button } from "@mui/material"
import { ArrowDownward, ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from "react";
import chroma from "chroma-js"
import { useTranslation } from "react-i18next";

const flatUIColorShades = {
    red: ["#ffdad5", "#ff6b6b", "#c0392b"],
    yellow: ["#fff3c4", "#fbc531", "#e1a200"],
    green: ["#d4f8d4", "#44bd32", "#218c74"],
    blue: ["#d6eaff", "#3498db", "#1e3799"],
    purple: ["#e8daff", "#8e44ad", "#5b2c6f"],
    brown: ["#e2c2a5", "#a57b61", "#6d4c41"],
    gray: ["#d3d3d3", "#808080", "#505050"],
    black: ["#666666", "#333333", "#000000"],
    beige: ["#f5e6cc", "#e1c699", "#c4a484"]
};

export const FilterCategory = (props) => {
    const { t } = useTranslation();
    const [groupedColors, setGroupedColors] = useState({});
    const [showAllColors, setShowAllColors] = useState(false);
    const [showAllCollections, setShowAllCollections] = useState(false);
    const initialVisibleCount = 5;

    useEffect(() => {
        if (props.filterName === "colors") {
            const grouped = groupColors(props.filterElements);
            setGroupedColors(grouped);
            props.onGroupedColors(grouped);
        }
    }, [props.filterElements]);

    const handleViewAllColors = () => setShowAllColors(true);
    const handleViewAllCollections = () => setShowAllCollections(true);

    const handleCheckboxChange = (checked, element) => {
        if (checked) {
            props.addToSelectedFilters(element, props.filterName);
        } else {
            props.removeFromSelectedFilters(element, props.filterName);
        }
    };

    const isItCheckedColor = (colorName) => {
        return props.selectedFilters["colors"]?.findIndex(e => e === groupedColors[colorName][0]) >= 0;
    };

    return (
        <Accordion defaultExpanded disableGutters sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                <Typography>
                    {props.name}
                    {props.filterName === "colors"
                        ? (() => {
                            const count = Object.keys(groupedColors).filter(group =>
                                props.selectedFilters["colors"]?.some(color => groupedColors[group].includes(color))
                            ).length;
                            return count > 0 ? ` (${count})` : "";
                        })()
                        : (props.selectedFilters[props.filterName]?.length > 0 ? ` (${props.selectedFilters[props.filterName].length})` : "")
                    }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexDirection="column">

                    {/* Colors Filter */}
                    {props.filterName === "colors" && groupedColors && (
                        <>
                            {Object.keys(groupedColors)
                                .filter(colorName => !props.showSelectedOnly || props.selectedFilters["colors"]?.some(color => groupedColors[colorName].includes(color)))
                                .slice(0, showAllColors ? Object.keys(groupedColors).length : initialVisibleCount)
                                .map(colorName => {
                                    const shades = flatUIColorShades[colorName] || flatUIColorShades["gray"];

                                    return (
                                        <Box key={colorName} sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            marginBottom: 2
                                        }}>
                                            {/* Color Gradient Swatch */}
                                            <Box sx={{
                                                display: "flex",
                                                borderRadius: 2,
                                                overflow: "hidden",
                                                width: 40,
                                                height: 40,
                                                gap: 0.05
                                            }}>
                                                {shades.map((shade, index) => (
                                                    <Box key={index} sx={{
                                                        backgroundColor: shade,
                                                        flex: 1,
                                                        marginRight: index < shades.length - 1 ? "2px" : 0,
                                                        borderTopLeftRadius: index === 0 ? 5 : 0,
                                                        borderBottomLeftRadius: index === 0 ? 5 : 0,
                                                        borderTopRightRadius: index === shades.length - 1 ? 5 : 0,
                                                        borderBottomRightRadius: index === shades.length - 1 ? 5 : 0,
                                                    }} />
                                                ))}
                                            </Box>

                                            {/* Color Name */}
                                            <Typography textTransform={"capitalize"} sx={{ flex: 1, marginLeft: 2 }}>
                                                {colorName}
                                            </Typography>

                                            {/* Checkbox */}
                                            <Checkbox
                                                onChange={(event) => handleCheckboxChange(!isItCheckedColor(colorName), groupedColors[colorName])}
                                                checked={isItCheckedColor(colorName)}
                                            />
                                        </Box>
                                    );
                                })}

                            {Object.keys(groupedColors).length > initialVisibleCount && !showAllColors && !props.showSelectedOnly && (
                                <Button
                                    onClick={handleViewAllColors}
                                    sx={{
                                        textTransform: "none",
                                        alignSelf: "center",
                                        marginTop: 1,
                                        color: "#333"
                                    }}
                                    endIcon={<ArrowDownward />}
                                >
                                    {t("VISUALIZER.BUTTONS.FILTERS.VIEW_ALL")}
                                </Button>

                            )}
                        </>
                    )}

                    {/* Other Filters (e.g., Collections, Categories, Brands) */}
                    {props.filterName !== "colors" && (
                        <Box>
                            {props.filterElements
                                .filter(element => !props.showSelectedOnly || props.selectedFilters[props.filterName]?.includes(element))
                                .slice(0, showAllCollections ? props.filterElements.length : initialVisibleCount)
                                .map((element, index) => (
                                    <Box key={index} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        paddingY: 1
                                    }}>
                                        <Typography>{element}</Typography>
                                        <Checkbox
                                            color="primary"
                                            onChange={(event) => handleCheckboxChange(event.target.checked, element)}
                                            checked={props.selectedFilters[props.filterName]?.includes(element)}
                                        />
                                    </Box>
                                ))}

                            {props.filterElements.length > initialVisibleCount && !showAllCollections && !props.showSelectedOnly && (
                                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <Button
                                        onClick={handleViewAllCollections}
                                        sx={{ textTransform: "none", marginTop: 1, color: "#333" }}
                                        endIcon={<ArrowDownward />}
                                    >
                                        {t("VISUALIZER.BUTTONS.FILTERS.VIEW_ALL")}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )}

                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

function getColorCategory(hex) {
    if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
        console.error(`Invalid color format: ${hex}`);
        return "unknown";
    }

    const color = chroma(hex);
    const [hue, saturation, lightness] = color.hsl();

    if (saturation < 0.15) {
        if (lightness > 0.85) return "gray";
        if (lightness < 0.15) return "black";
        return "gray";
    }

    if (hue >= 0 && hue < 20) return "red";
    if (hue >= 20 && hue < 60) return "yellow";
    if (hue >= 60 && hue < 150) return "green";
    if (hue >= 150 && hue < 250) return "blue";
    if (hue >= 250 && hue < 310) return "purple";
    if (hue >= 310 && hue < 360) return "red";

    return "unknown";
}

function groupColors(colors) {
    return colors.reduce((groups, color) => {
        const category = getColorCategory(color);
        if (!groups[category]) groups[category] = [];
        groups[category].push(color);
        return groups;
    }, {});
}
