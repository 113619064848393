import { useEffect, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import {BrowserView, MobileView} from 'react-device-detect';
import { ARModelViewer } from "../modules/ARVisualizer/components/ARModelViewer";
import { ARModelViewerAndroid } from "../modules/ARVisualizer/components/ARModelViewerAndroid";
import { VendorThemeProvider } from "../providers/VendorThemeProvider";

export function ARVisualizer() {
    const [fadeIn, setFadeIn] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);

    useEffect(() => {
        setTimeout(() => setFadeIn(true), 300);
        setIsAndroid(/android/i.test(navigator.userAgent));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);
        }, 300)
    }, [])

    return (
        <VendorThemeProvider>
            <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease' }}>
                <BrowserView>
                    <QRCode value={window.location.href} />
                </BrowserView>
                <MobileView>
                    {isAndroid ? <ARModelViewerAndroid /> : <ARModelViewer />}
                </MobileView>
            </div>
        </VendorThemeProvider>
    );
}