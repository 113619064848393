import { useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setFloorsStartingPage, setPreselectedSKU, setProducts, setSelectedFloorsProduct, setSelectedPageForSurface, setSelectedProduct, setSelectedSKUForSurface, setSelectedWallsProduct, setStartingPage, setWallsStartingPage } from "../../../../redux/product/productActions";
import { MobileSearchBar } from "./mobile/_MobileSearchBar";
import { WebSearchBar } from "./web/_WebSearchBar";
import { FilterDialog } from "../Dialogs/FilterDialog";
import { SpaceCatalogService } from "../../services/space-catalog.service";
import { FullState } from "../../../../redux/rootReducer";
import { toggleBookmarks } from "../../../../redux/general/generalActions";
import { IFrameContext } from "../../../../providers/IFrameContext";
import { WebViewContext } from "../../../../providers/WebViewContext";

export const shouldUseSelectedPageRef = { current: true };

export const SearchBar = (props) => {
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)
  const [searchInputText, setSearchInputText] = useState("")
  const theme = useTheme();
  const dispatch = useDispatch()
  const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const { products, filter, startingPage, selectedProduct, selectedSKUBySurface, selectedPageBySurface } = useSelector((state: FullState) => state.productsCatalog)
  const { isFloorSelected, isWallsSelected, isRugSelected } = useSelector((state: FullState) => state.surfaces)
  const isOnIframe = useContext(IFrameContext);
  const isOnWebView = useContext(WebViewContext);
  const { preselectedSKU } = useSelector((state: FullState) => state.productsCatalog);

  const { isOnBookmarks } = useSelector((state: FullState) => state.generals)

  const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())

  const handleToggleBookmarks = async (state) => {
    dispatch(toggleBookmarks(state))
  }

  const handleOpenDialog = () => setIsFilterDialogOpen(!isFilterDialogOpen);

  const handleToggleSearchBar = () => {
    setIsSearchBarOpen(!isSearchBarOpen)
  }

  const handleSearchProducts = (text) => {
    setSearchInputText(text)
  }

  const updateDisplayedProducts = async () => {
    const surface = isFloorSelected
      ? "floor"
      : isWallsSelected
        ? "walls"
        : isRugSelected
          ? "interactive_floor"
          : "";

    const activeFiltersApplied = !!(
      filter?.specifics?.colors?.length ||
      filter?.specifics?.collections?.length ||
      filter?.name
    );

    const selectedSKU = selectedSKUBySurface[surface];
    const activeSKU = preselectedSKU ?? selectedSKU;

    console.log("🔍 updateDisplayedProducts CALLED");
    console.log("🌊 selectedSurface:", surface);
    console.log("🔧 selectedSKU:", selectedSKU);
    console.log("🧠 preselectedSKU:", preselectedSKU);
    console.log("📦 selectedPageBySurface[surface]:", selectedPageBySurface[surface]);
    console.log("📥 filter.page:", filter.page);
    console.log("🪝 shouldUseSelectedPageRef.current:", shouldUseSelectedPageRef.current);

    let currentPage = 1;

    if (
      shouldUseSelectedPageRef.current &&
      selectedSKU &&
      selectedPageBySurface[surface]
    ) {
      currentPage = selectedPageBySurface[surface];
    } else if (filter.page) {
      currentPage = filter.page;
    }

    console.log("✅ Final currentPage used in query:", currentPage);

    shouldUseSelectedPageRef.current = false;

    const query: any = {
      ...filter,
      application_types: [surface],
      limit: 15,
      page: currentPage,
    };

    if (activeFiltersApplied) {
      delete query.sku;
      delete query.returnPageWithProduct;
      query.page = 1;
    }

    let response;
    let usedSkuQuery = false;
    let matchedProduct = null;

    const shouldUseSkuQuery =
      !!preselectedSKU ||
      (
        activeSKU &&
        !activeFiltersApplied &&
        currentPage === selectedPageBySurface[surface] &&
        currentPage === 1 &&
        products.length === 0
      );

    console.log("📤 Final query sent to API:", query);

    try {
      if (shouldUseSkuQuery) {
        query.sku = activeSKU;
        query.returnPageWithProduct = true;
        usedSkuQuery = true;

        response = await spaceCatalogService.getProductBySKU(activeSKU, query);

        if (response?.data?.length > 0) {
          matchedProduct = response.data.find(p => p.sku === activeSKU);
          dispatch(setSelectedSKUForSurface(surface, activeSKU));
          dispatch(setPreselectedSKU(null));

          // 🧠 Align selected page with backend offset
          if (
            typeof response.offset === "number" &&
            response.limit
          ) {
            const backendPage = Math.floor(response.offset / response.limit) + 1;
            console.log("📦 Backend-computed page:", backendPage);
            dispatch(setSelectedPageForSurface(surface, backendPage));
            dispatch(setStartingPage(backendPage));
            currentPage = backendPage; // override for fetch and reorder
          }
        }
      } else {
        response = await spaceCatalogService.fetchProducts(query);
      }

      if (!response?.data) {
        console.warn("No products returned for surface:", surface);
        return;
      }

      const isPaginated = currentPage > 1 && !usedSkuQuery;
      let newProducts;

      if (isPaginated) {
        newProducts = [...products, ...response.data].filter(
          (product, index, self) =>
            index === self.findIndex(p => p.id === product.id)
        );
      } else {
        newProducts = response.data;
      }

      if (activeSKU) {
        const index = newProducts.findIndex((p) =>
          p.sku === activeSKU || p.siblings?.some(s => s.sku === activeSKU)
        );

        if (index > 0) {
          const [found] = newProducts.splice(index, 1);
          newProducts.unshift(found);
        }
      }

      dispatch(setProducts(newProducts, response.nextPage, response.offset, response.limit));

      // ✅ Now that product list is in Redux, safely update selected product
      if (matchedProduct) {
        setTimeout(() => {
          dispatch(setSelectedProduct(matchedProduct));
        }, 0);
      }

    } catch (error) {
      console.error("updateDisplayedProducts error:", error);
    }
  };

  /* UPDATE products on filter change */
  useEffect(() => {
    updateDisplayedProducts();
  }, [filter]);

  /* UPDATE wallsStartingPage/floorsStartingPage on selectedProduct change */
  useEffect(() => {
    if (isWallsSelected) {
      dispatch(setWallsStartingPage(filter.page ?? 1))
      dispatch(setSelectedWallsProduct(selectedProduct))
    } else {
      dispatch(setFloorsStartingPage(filter.page ?? 1))
      dispatch(setSelectedFloorsProduct(selectedProduct))
    }
  }, [selectedProduct])


  const handleKeyDown = (key) => {
    if (key === 'Enter') {
      dispatch(setFilter({
        ...filter,
        name: searchInputText
      }))
    }
  }

  return (
    <>
      {(isMDandDown && props.force !== "web") && <MobileSearchBar
        isSearchBarOpen={isSearchBarOpen}
        searchInputText={searchInputText}
        handleSearchProducts={handleSearchProducts}
        handleToggleSearchBar={handleToggleSearchBar}
        handleToggleBookmarks={() => handleToggleBookmarks(!isOnBookmarks)}
        handleKeyDown={handleKeyDown}
        handleOpenDialog={handleOpenDialog} />}
      {(!isMDandDown || props.force === "web") && <WebSearchBar
        isSearchBarOpen={isSearchBarOpen}
        searchInputText={searchInputText}
        actAs={props.actAs}
        handleSearchProducts={handleSearchProducts}
        handleToggleSearchBar={handleToggleSearchBar}
        handleKeyDown={handleKeyDown}
        handleOpenDialog={handleOpenDialog} />}
      <FilterDialog
        isOpen={isFilterDialogOpen}
        products={products}
        handleOpen={() => handleOpenDialog()} />
    </>
  );
}