import { createContext, useState, useContext, Dispatch, SetStateAction } from "react";

interface UIContextType {
  isWebPoppingBarVisible: boolean;
  setIsWebPoppingBarVisible: Dispatch<SetStateAction<boolean>>;
  isLoaderVisible: boolean;
  setIsLoaderVisible: Dispatch<SetStateAction<boolean>>;
}

export const UIContext = createContext<UIContextType>({
  isWebPoppingBarVisible: false,
  setIsWebPoppingBarVisible: () => {},
  isLoaderVisible: false,
  setIsLoaderVisible: () => {},
});

export const UIProvider = ({ children }) => {
  const [isWebPoppingBarVisible, setIsWebPoppingBarVisible] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false); // NEW STATE

  return (
    <UIContext.Provider value={{ isWebPoppingBarVisible, setIsWebPoppingBarVisible, isLoaderVisible, setIsLoaderVisible }}>
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => useContext(UIContext);
