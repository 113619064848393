import { TransformComponent } from "react-zoom-pan-pinch"
import { SelectionIcons } from "../../Common/SelectionIcons"
import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Global } from "@emotion/react"
import CrossfadeImage from "react-crossfade-image"
import { useTranslation } from "react-i18next"

export const InitialSpaceWeb = (props) => {
    const [imageSrc, setImageSrc] = useState(props.selectedSpace.uncroppedThumbnailPath);
    const [isVisible, setIsVisible] = useState(!props.areIconsVisible);
    const [t] = useTranslation()

    useEffect(() => {
        // Update the imageSrc state when props.selectedSpaceResult changes
        if (props.selectedSpaceResult) {
            setImageSrc(props.selectedSpaceResult)
        } else {
            setImageSrc(props.selectedSpace.uncroppedThumbnailPath);
        }
    }, [props.selectedSpaceResult, props.selectedSpace.uncroppedThumbnailPath]);

    useEffect(() => {
        if (!props.areIconsVisible) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 1500);

            return () => clearTimeout(timer);
        }
    }, [props.areIconsVisible]);

    return (
        <TransformComponent wrapperStyle={{ width: "100%" }} contentStyle={{ width: "100%", justifyContent: "center", alignItems: "center" }}
        >
            <Global
                styles={{
                    '.react-transform-wrapper': {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    },
                }}
            />
            {
                <Box sx={{
                    backgroundColor: "#FFFFFF90",
                    border: "1px solid #00000050",
                    borderRadius: 8,
                    padding: 1,
                    width: "50%",
                    textAlign: "center",
                    position: "absolute",
                    top: "10px",
                    left: "25%",
                    opacity: isVisible ? 1 : 0,
                    visibility: isVisible ? "visible" : "hidden",
                    transition: "opacity 0.5s ease-in-out, visibility 0.5s",
                    zIndex: 500000
                }}>
                    <Typography variant="caption" style={{ color: "#00000080" }}>
                        {t('VISUALIZER.COMPARE.UNHIDE_MARKERS')}
                    </Typography>
                </Box>
            }
            <Box onClick={() => props.setAreIconsVisible(!props.areIconsVisible)}>
                <CrossfadeImage src={imageSrc}
                    id="space"
                    style={{
                        width: "100%",
                        height: "calc(100vh - 90px)",
                        objectFit: "contain"
                    }}
                    duration={500}
                />
            </Box>


            <SelectionIcons {...props}
                setAreIconsVisible={(visibility) => props.setAreIconsVisible(visibility)}
                areIconsVisible={props.areIconsVisible} />

        </TransformComponent>
    )
}

