import { useEffect, useState } from "react";
import { FilterCategory } from "./_FilterCategory";
import { SpaceCatalogService } from "../../services/space-catalog.service";
import { Button, Drawer, DialogContent, useTheme, Box, Typography, Switch } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from "react-redux";
import { FullState } from "../../../../redux/rootReducer";
import { setFilter, setProducts } from "../../../../redux/product/productActions";
import { useTranslation } from "react-i18next";
import { shouldUseSelectedPageRef } from "../SideBar/_SearchBar";

export const FilterDialog = (props) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<any>();
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService());
    const { isFloorSelected, isWallsSelected, isRugSelected } = useSelector((state: FullState) => state.surfaces);
    const { filter } = useSelector((state: FullState) => state.productsCatalog);
    const [showSelectedOnly, setShowSelectedOnly] = useState(false);
    const [groupedColors, setGroupedColors] = useState({});

    useEffect(() => {
        if (Object.values(selectedFilters).flat().length === 0) {
            setShowSelectedOnly(false);
        }
    }, [selectedFilters]);

    useEffect(() => {
        if (props.isOpen) {
            updateFilters();
            setSelectedFilters(filter.specifics || {});
        } else {
            setFilters({});
        }
    }, [props.isOpen]);

    const updateFilters = async () => {
        const selectedSurface = isFloorSelected ? "floor" : isWallsSelected ? "walls" : isRugSelected ? "interactive_floor" : "";
        const productFilters = await spaceCatalogService.getProductsFilters(selectedSurface);
        setFilters(productFilters);
    };

    const addToSelectedFilters = (item, filterName) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: [...(prevFilters[filterName] || []), ...(Array.isArray(item) ? item : [item])]
        }));
    };

    const removeFromSelectedFilters = (item, filterName) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: (prevFilters[filterName] || []).filter(e => Array.isArray(item) ? !item.includes(e) : e !== item)
        }));
    };

    const resetFilters = () => {
        setSelectedFilters({});
        shouldUseSelectedPageRef.current = true;
        dispatch(setFilter({}));
        dispatch(setProducts([]));
        props.handleOpen();
    };

    const confirmFilters = () => {
        const isReset = !selectedFilters?.colors?.length && !selectedFilters?.collections?.length;

        shouldUseSelectedPageRef.current = isReset;

        if(isReset) {
            dispatch(setProducts([]));
        }

        dispatch(setFilter({ ...filter, specifics: selectedFilters, page: 1 }));
        props.handleOpen();
    };

    return (
        <Drawer
            anchor="left"
            open={props.isOpen}
            onClose={() => { }}
            ModalProps={{
                disableEscapeKeyDown: true,
                keepMounted: true
            }}
            sx={{
                width: isMobile ? "100vw" : "26.5vw",
                height: isMobile ? "100vh" : "auto",
                flexShrink: 0,
                "& .MuiBackdrop-root": {
                    backgroundColor: "transparent"
                },
                "& .MuiDrawer-paper": {
                    width: isMobile ? "100vw" : "26.5vw",
                    height: "100vh",
                    boxSizing: "border-box",
                    boxShadow: '1px 0 5px -1px #88888850',
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                }
            }}
        >
            {/* Header with close button */}
            <Box sx={{ display: "flex", alignItems: "center", padding: "16px", borderBottom: "1px solid #ddd" }}>
                <Box sx={{
                    flexGrow: 1,
                    fontSize: "18px",
                    fontWeight: "bold",
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Typography>
                        {(() => {
                            const colorCount = Object.keys(groupedColors || {}).filter(group =>
                                selectedFilters["colors"]?.some(color => groupedColors[group].includes(color))
                            ).length;

                            const otherFiltersCount = Object.keys(selectedFilters)
                                .filter(key => key !== "colors")
                                .reduce((acc, key) => acc + (selectedFilters[key]?.length || 0), 0);

                            const totalCount = colorCount + otherFiltersCount;

                            return totalCount > 0
                                ? `${t("VISUALIZER.BUTTONS.FILTERS.SELECTED_ONLY")} (${totalCount})`
                                : t("VISUALIZER.BUTTONS.FILTERS.NO_FILTERS");
                        })()}
                    </Typography>

                </Box>
                {Object.values(selectedFilters).flat().length > 0 && (
                    <Switch
                        checked={showSelectedOnly}
                        onChange={() => setShowSelectedOnly(prev => !prev)}
                        color="primary"
                    />
                )}

            </Box>

            <DialogContent sx={{ flexGrow: 1, overflowY: "auto", padding: "16px" }}>
                {filters && Object.keys(filters).map((filterName, index) => {
                    const filteredElements = filters[filterName].filter(el => !showSelectedOnly || selectedFilters[filterName]?.includes(el));

                    if (showSelectedOnly && filteredElements.length === 0) return null;

                    return (
                        filterName !== "variants" && filters[filterName].length > 0 && (
                            <FilterCategory
                                key={index}
                                name={capitalizeFirstLetter(filterName)}
                                filterName={filterName}
                                filterElements={filters[filterName]}
                                selectedFilters={selectedFilters}
                                showSelectedOnly={showSelectedOnly}
                                removeFromSelectedFilters={removeFromSelectedFilters}
                                addToSelectedFilters={addToSelectedFilters}
                                showLimited={true}
                                onGroupedColors={(grouped) => setGroupedColors(grouped)}
                            />

                        )
                    );
                })}
            </DialogContent>


            {/* Fixed Bottom Buttons */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                    borderTop: "1px solid #ddd",
                    background: "white",
                    position: "sticky",
                    bottom: 0,
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={resetFilters}
                    sx={{ flex: 1, marginRight: 1, textAlign: "center" }}
                >
                    {t("VISUALIZER.BUTTONS.FILTERS.RESET")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={confirmFilters}
                    sx={{ flex: 1, textAlign: "center" }}
                >
                    {t("VISUALIZER.BUTTONS.FILTERS.CONFIRM")}
                </Button>
            </Box>
        </Drawer>
    );
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
