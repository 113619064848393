import React, { useEffect, useState } from 'react';
import { Grid, ImageList, useMediaQuery, useTheme, ImageListItem, Skeleton, Box } from '@mui/material';
import { Container } from '@mui/system';
import { SpacePickerService } from '../services/space-picker.service';
import { SpacesList } from '../components/SpacesList/SpacesList';
import { useSelector } from "react-redux";
import { FullState } from '../../../redux/rootReducer';
import { AddSpaceItem } from '../components/SpacesList/AddSpaceItem';
import { EventsService } from '../../../common/services/events.service';
import { Event, EventTypes, SpaceListPageViewedEvent } from '../../../common/models/events/event.model';

const visualizerAnimation = require("../../../assets/visualizer_animation_ardealy.mp4");

export function SpacesGallery() {
  const predefinedSpaces = useSelector((state: FullState) => state.spacesPicker.predefinedSpaces)
  const theme = useTheme();
  const [spacePickerService] = useState<SpacePickerService>(new SpacePickerService())
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSM = useMediaQuery(theme.breakpoints.only('sm'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    await spacePickerService.getPredefinedSpaces();
    setLoading(false);
    EventsService.push(new Event(EventTypes.SpaceListPageViewedEvent, new SpaceListPageViewedEvent(predefinedSpaces)));
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid
          item
          md={3.5}
          xs={12}
          sx={{
            ...(isXS || isSM
              ? {}
              : {
                position: "sticky",
                top: 0,
                height: "100vh",
                overflowY: "auto",
              }),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Video */}
          <video
            src={visualizerAnimation}
            style={{
              marginTop: 16,
              width: "100%",
              borderRadius: "16px",
              overflow: "hidden"
            }}
            muted
            loop
            autoPlay
          />

          <Box mt={2} width="100%">
            <AddSpaceItem />
          </Box>
        </Grid>

        <Grid item md={8.5} xs={12}>
          {loading ? (
            <ImageList variant="masonry" cols={isXS || isSM ? 2 : 3} gap={18}>
              {[...Array(9)].map((_, index) => (
                <ImageListItem key={index}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={Math.floor(Math.random() * (250 - 150) + 150)}
                    animation="wave"
                    sx={{ borderRadius: "8px" }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <SpacesList suggested={predefinedSpaces} />
          )}
        </Grid>
      </Grid>
    </Container>
  );

}