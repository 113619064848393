import { Box, Typography } from "@mui/material"
import { SearchBar } from "../_SearchBar"
import { useState } from "react"
import { useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { MobilePoppingBar } from "./_MobilePoppingBar"
import { ProductList } from "../../Product/ProductList"
import { MobileSwipeableDrawer } from "../../../../../common/components/SwipeableDrawer"
import { ResultActionsMobile } from "../../Result/ResultActions/Mobile/_ResultActionsMobile"
import { Global } from "@emotion/react"
import { getFaIconsStyles } from "../../../../../common/styles/styles"
import { SettingsService } from "../../../../../common/services/settings.service"

export const MobileSideBar = () => {
    const {products} = useSelector((state: FullState) => state.productsCatalog)
    
    const [displayedAs, setDisplayedAs] = useState<string>("mobile")
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)

    const spaceHasFloorSurface = () => selectedSpace.surfaces.includes("floor")
    const spaceHasWallSurface = () => selectedSpace.surfaces.includes("walls")
    const spaceHasRugsSurface = () => selectedSpace.surfaces.includes("interactive_floor")

    const showContentAs = (showAs: string) => {
      setDisplayedAs(showAs)
    }

    const numberOfSurfaceSelection = () => {
      let numberOfSurfaces = 0;
      numberOfSurfaces = SettingsService.vendorHasFloor() && spaceHasRugsSurface() ? numberOfSurfaces+1:numberOfSurfaces
      numberOfSurfaces = SettingsService.vendorHasWalls() && spaceHasWallSurface() ? numberOfSurfaces+1:numberOfSurfaces
      numberOfSurfaces = SettingsService.vendorHasRugs() && spaceHasFloorSurface() ? numberOfSurfaces+1:numberOfSurfaces

      return numberOfSurfaces
  }

    return (<>
                <MobilePoppingBar />
                <MobileSwipeableDrawer showContentAs={showContentAs}>
                  <Box sx={{display: "flex", flexDirection: "column", height: "250px", paddingTop: 1, position: "relative"}}>
                    {/* If drawer is Closed */}
                    <Box sx={{display: "flex", flexDirection: "column", height: "250px", paddingTop: 1, position: "absolute", top: 0, width: "100%"}}>
                        <Box style={{width: "100vw", flex: "1"}} paddingInline={1} className={displayedAs === "mobile" ? "visible": "hidden"}>
                            {displayedAs === "mobile" && <SearchBar force={displayedAs} />}
                            {displayedAs === "mobile" && <ProductList catalog={products} view={displayedAs} showContentAs={showContentAs}/>}
                        </Box>
                    </Box>
                    {/* If drawer is Open */}
                    <Box sx={{display: "flex", flexDirection: "column", height: "250px", paddingTop: 1, position: "absolute", top: 0, width: "100%", marginBottom: 2}}>
                        <Global styles={{
                            '.product-list-web': {
                              height: numberOfSurfaceSelection() === 1 ? "calc(100vh - 140px)!important":"calc(100vh - 220px)!important"
                            },
                          }}/>
                        <Box style={{width: "100vw", flex: "1"}} paddingInline={1} className={displayedAs === "web" ? "visible": "hidden"}>
                              {displayedAs === "web" && <SearchBar force={displayedAs} actAs={true} />}
                              {products.length > 0 && displayedAs === "web" && <ProductList catalog={products} view={displayedAs} showContentAs={showContentAs}/>}
                              {products.length === 0 && <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                                  <Box marginBlock={2}><i className="fad fa-heart-broken fa-2xl" style={getFaIconsStyles()} ></i></Box>
                                  <Typography marginInline={2} textAlign={"center"} variant="body2"> Uh-oh! It seems like there are no products in your favorites right now!</Typography>
                                  <Typography marginInline={2} marginBlock={2} textAlign={"center"} variant="caption"> Swipe down to see all products!</Typography>
                                </Box>}
                        </Box>
                    </Box>
                  </Box>
                </MobileSwipeableDrawer>
                <ResultActionsMobile />
            </>
    )
}