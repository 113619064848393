import { Box, Button, FormControlLabel, Modal, Switch, TextField, Typography, Divider } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useState, useRef, useCallback } from "react"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { FullState } from "../../../../../redux/rootReducer"
import { setCloseCompare, setResultCompare } from "../../../../../redux/general/generalActions"
import { v4 } from 'uuid';
import { EventsService } from "../../../../../common/services/events.service"
import { ChangeSpaceClickedEvent, Event, EventTypes, ProductResultDownloadedEvent } from "../../../../../common/models/events/event.model"
import { useNavigate } from "react-router-dom"
import { IFrameContext } from "../../../../../providers/IFrameContext"
import { WebViewContext } from "../../../../../providers/WebViewContext"
import { SettingsService } from "../../../../../common/services/settings.service"
import { useTranslation } from "react-i18next"
import { ShareSpaceModal } from "../../Dialogs/ShareSpaceModal"
import { VendorThemeContext } from "../../../../../providers/VendorThemeProvider"
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ShareIcon from "@mui/icons-material/Share";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import { useUIContext } from "../../../../../providers/UIContext"

export const MobilePoppingBar = () => {
    const { vendorAlias } = useContext(VendorThemeContext);
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isOnIframe = useContext(IFrameContext);
    const isOnWebView = useContext(WebViewContext);
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const { selectedProductResult, selectedProduct } = useSelector((state: FullState) => state.productsCatalog)
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const { visualizeData } = useSelector((state: FullState) => state.surfaces)
    const [windowParent, setWindowParent] = useState(window.parent)
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [userEmail, setUserEmail] = useState(localStorage.getItem("userEmail") || "");
    const [emailInput, setEmailInput] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [advertisingConsent, setAdvertisingConsent] = useState(false);
    const [showConsentSwitch, setShowConsentSwitch] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    const [shareId, setShareId] = useState()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [dynamicActions, setDynamicActions] = useState([]);
    const { setIsWebPoppingBarVisible } = useUIContext();

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        //@ts-ignore
        setWindowParent(isOnWebView ? window.ReactNativeWebView : window.parent)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setIsWebPoppingBarVisible(true);
        }, 100);

        return () => {
            setIsWebPoppingBarVisible(false);
        };
    }, []);

    useEffect(() => {
        if (dynamicActions.length === 0) return;

        const updateVisibleButtons = () => {
            const containerWidth = containerRef.current?.offsetWidth || 0;
            const buttonWidth = 90;
            const hasCloseButton = isOnIframe || isOnWebView;
            const reservedSlots = hasCloseButton ? 2 : 1;
            const maxVisible = Math.max(1, Math.floor(containerWidth / buttonWidth) - reservedSlots);

            setVisibleActions(dynamicActions.slice(0, maxVisible));
            setHiddenActions(dynamicActions.slice(maxVisible));
        };

        updateVisibleButtons();
        window.addEventListener("resize", updateVisibleButtons);

        return () => {
            window.removeEventListener("resize", updateVisibleButtons);
        };
    }, [dynamicActions]);

    useEffect(() => {
        const updatedActions = [
            {
                label: t("VISUALIZER.BUTTONS.ORDER"),
                icon: <ShoppingCartIcon />,
                onClick: () => {
                    handleAddToCartClick();
                },
                disabled: !selectedProduct
            },
            {
                label: t("VISUALIZER.BUTTONS.AR_LIVE"),
                icon: <ViewInArIcon />,
                onClick: () => {
                    handleARPreview();
                },
                disabled: !selectedProduct
            },
            { label: t("VISUALIZER.BUTTONS.DOWNLOAD"), icon: <CloudDownloadIcon />, onClick: handleDownloadResult },
            { label: t("VISUALIZER.BUTTONS.SHARE"), icon: <ShareIcon />, onClick: handleShareSpaceResult },
            { label: t("VISUALIZER.BUTTONS.CHANGE_SPACE"), icon: <ImageIcon />, onClick: handleChangeSpace },
        ];

        setDynamicActions(updatedActions);
    }, [selectedProduct]);


    const style = {
        marginLeft: 5,
        width: "40px",
        fontSize: 22, cursor: "pointer", boxShadow: '1px 0 5px -2px #888', backgroundColor: "white", padding: 4, borderRadius: 8, paddingTop: 6, paddingBottom: 6,
    }

    useEffect(() => {
        document.getElementById("model-viewer")?.remove();
    }, [selectedProduct])

    const handleChangeSpace = () => {
        EventsService.push(new Event(EventTypes.ChangeSpaceClickedEvent, new ChangeSpaceClickedEvent(selectedSpace, visualizeData)))

        dispatch(setCloseCompare(true))
        dispatch(setResultCompare(false))
        navigate(-1)
    }

    const handleCloseIframe = () => {
        //@ts-ignore
        windowParent.postMessage(JSON.stringify(
            {
                type: "FRAME_CLOSED"
            }),
            "*"
        );
    }

    // Email Validation Function
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Update email validation state
    useEffect(() => {
        setIsValidEmail(validateEmail(emailInput));
    }, [emailInput]);

    useEffect(() => {
        const consentData = JSON.parse(localStorage.getItem("cookieConsent")) || {};
        const hasAcceptedAdvertising = consentData.advertisingCookies ?? false;
        setAdvertisingConsent(hasAcceptedAdvertising);
        setShowConsentSwitch(!hasAcceptedAdvertising);
    }, []);

    const handleDownloadResult = () => {
        const consentData = JSON.parse(localStorage.getItem("cookieConsent")) || {};
        const hasAcceptedAdvertising = consentData.advertisingCookies ?? false;

        setAdvertisingConsent(hasAcceptedAdvertising);
        setShowConsentSwitch(!hasAcceptedAdvertising);

        if (!userEmail || (!advertisingConsent && showConsentSwitch)) {
            setIsEmailModalOpen(true);
            return;
        }

        triggerDownload(userEmail);
    };

    const triggerDownload = (email) => {
        if (selectedProduct) {
            EventsService.push(new Event(EventTypes.ProductResultDownloadedEvent, new ProductResultDownloadedEvent(visualizeData, email)));

            const element = document.getElementById("download");
            if (element && element instanceof HTMLAnchorElement && element.href) {
                const now = new Date();
                const date = now.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ');
                const time = now.toTimeString().split(' ')[0].replace(/:/g, '_');
                const formattedDateTime = `${date} ${time}`;
                const filename = `${SettingsService.settings.company} - ${formattedDateTime} GMT.jpg`;
                const tempLink = document.createElement('a');
                tempLink.href = element.href;
                tempLink.download = filename;
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
            }
        }
    };

    const handleEmailSubmit = () => {
        if (isValidEmail && (!showConsentSwitch || advertisingConsent)) {
            localStorage.setItem("userEmail", emailInput);
            setUserEmail(emailInput);
            setIsEmailModalOpen(false);
            triggerDownload(emailInput);
        }
    };

    const handleToggleConsent = () => {
        const newConsentState = !advertisingConsent;
        setAdvertisingConsent(newConsentState);

        const consentData = JSON.parse(localStorage.getItem("cookieConsent")) || {};
        consentData.advertisingCookies = newConsentState;
        localStorage.setItem("cookieConsent", JSON.stringify(consentData));

        setShowConsentSwitch(!newConsentState);
    };

    const handleShareSpaceResult = async () => {
        const shareId = await spaceCatalogService.getShareId(selectedSpace._id, visualizeData)
        setShareId(shareId)
        setIsShareModalOpen(true)
    }

    const handleAddToCartClick = () => {
        if (!selectedProduct) return;

        const isValidUrl = (url) => {
            try {
                new URL(url.startsWith('http') ? url : `https://${url}`);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isOnWebView) {
            windowParent.postMessage(JSON.stringify(
                {
                    type: "ADDED_TO_CART",
                    value: selectedProduct
                }),
                "*"
            );
        } else {
            if (selectedProduct.addToCartLink && isValidUrl(selectedProduct.addToCartLink)) {
                window.open(selectedProduct.addToCartLink.startsWith('http') ? selectedProduct.addToCartLink : `https://${selectedProduct.addToCartLink}`, '_blank');
            } else if (selectedProduct.link && isValidUrl(selectedProduct.link)) {
                window.open(selectedProduct.link.startsWith('http') ? selectedProduct.link : `https://${selectedProduct.link}`, '_blank');
            }
        }
    };

    const handleARPreview = () => {
        if (!selectedProduct) return;

        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const company = encodeURIComponent(SettingsService.settings.alias);
        const productId = encodeURIComponent(selectedProduct.id);
        const arUrl = `${baseUrl}/ar/${company}/products/${productId}`;
        window.open(arUrl, '_blank');
    };

    const [visibleActions, setVisibleActions] = useState([]);
    const [hiddenActions, setHiddenActions] = useState([]);

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((item, index) => item.label === arr2[index].label);
    };

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    top: 8,
                    left: 8,
                    right: 8,
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: "0px -3px 10px rgba(0,0,0,0.1)",
                    padding: "2px 12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "8px",
                }}
                ref={containerRef}
            >
                {/* Close Button */}
                {(isOnIframe || isOnWebView) && (
                    <>
                        <IconButton onClick={handleCloseIframe} sx={{ color: "black" }}>
                            <CloseIcon />
                        </IconButton>
                        <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                            <Divider orientation="vertical" flexItem sx={{ height: 40 }} />
                        </div>
                    </>
                )}

                {/* Dynamically Displayed Actions */}
                {visibleActions.map((action, index) => (
                    <Button
                        key={index}
                        onClick={() => {
                            action.onClick();
                        }}
                        sx={{
                            color: "#3f4347",
                            textTransform: "none",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "auto",
                        }}
                    >
                        {action.icon}
                        <Typography variant="caption">{action.label}</Typography>
                    </Button>
                ))}


                {/* Overflow Menu (Kebab Icon) */}
                {hiddenActions.length > 0 && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                            <Divider orientation="vertical" flexItem sx={{ height: 40 }} />
                        </div>
                        <IconButton onClick={handleOpenMenu} sx={{ color: "black" }}>
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            sx={{
                                marginTop: '8px',
                                '& .MuiPaper-root': {
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    minWidth: '180px',
                                },
                            }}
                        >
                            {hiddenActions.length > 0 &&
                                hiddenActions.map((action, index) => (
                                    <MenuItem key={index} onClick={action.onClick} sx={{ padding: '8px 16px' }}>
                                        {action.icon}
                                        <Typography sx={{ marginLeft: "8px" }}>{action.label}</Typography>
                                    </MenuItem>
                                ))}
                        </Menu>

                    </>
                )}
            </Box>

            {/* Email Capture Modal */}
            <Modal open={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)}>
                <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 400, bgcolor: "white", boxShadow: 24, p: 4, borderRadius: 2 }}>
                    <Typography variant="h6">{t("VISUALIZER.LABELS.EMAIL_MODAL_TITLE")}</Typography>
                    <Typography variant="body2" color="textSecondary">{t("VISUALIZER.LABELS.EMAIL_MODAL_DESCRIPTION")}</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                        error={!isValidEmail && emailInput.length > 0}
                        helperText={!isValidEmail && emailInput.length > 0 ? t("VISUALIZER.LABELS.EMAIL_MODAL_EMAIL_ERROR") : ""}
                    />

                    {showConsentSwitch && (
                        <FormControlLabel
                            control={<Switch checked={advertisingConsent} onChange={handleToggleConsent} />}
                            label={t("VISUALIZER.LABELS.EMAIL_MODAL_COOKIE")}
                            sx={{ display: "block", marginTop: 2 }}
                        />
                    )}

                    {showConsentSwitch && !advertisingConsent && (
                        <Typography color="error" fontSize="0.8rem">
                            {t("VISUALIZER.LABELS.EMAIL_MODAL_COOKIE_ERROR")}
                        </Typography>
                    )}

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleEmailSubmit}
                        disabled={!isValidEmail || (showConsentSwitch && !advertisingConsent)}
                        sx={{ opacity: (!isValidEmail || (showConsentSwitch && !advertisingConsent)) ? 0.5 : 1 }}
                    >
                        {t("VISUALIZER.BUTTONS.SUBMIT_DOWNLOAD")}
                    </Button>
                </Box>
            </Modal>

            <a href={selectedProductResult ? selectedProductResult : ""} id="download" download="Image.png"></a>
            <ShareSpaceModal URL={window.location.host + '/share/' + vendorAlias + '/' + shareId} isOpen={isShareModalOpen} handleClose={() => setIsShareModalOpen(false)} />
        </>
    )
}