import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import { FullState } from '../../../../redux/rootReducer';
import { IFrameContext } from '../../../../providers/IFrameContext';
import { setFilter, setFloorsStartingPage, setPreselectedSKU, setProducts, setSelectedProduct, setStartingPage, setWallsStartingPage } from '../../../../redux/product/productActions';
import { SpaceCatalogService } from '../../../Visualizer/services/space-catalog.service';
import { ProductModel } from '../../../Visualizer/models/product.model';
import { useTranslation } from 'react-i18next';
import { WebViewContext } from '../../../../providers/WebViewContext';
import { Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

export function Header() {
  const { companyLogo } = useSelector((state: FullState) => state.generals)
  const isOnIframe = React.useContext(IFrameContext);
  const isOnWebView = React.useContext(WebViewContext);
  const [spaceCatalogService] = React.useState<SpaceCatalogService>(new SpaceCatalogService())
  const [product, setProduct] = React.useState<ProductModel>()
  const [windowParent, setWindowParent] = React.useState(window.parent)
  const { t } = useTranslation();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [showStickyBar, setShowStickyBar] = React.useState(false);
  const { selectedProduct } = useSelector((state: FullState) => state.productsCatalog)
  const { isFloorSelected, isWallsSelected } = useSelector((state: FullState) => state.surfaces)

  const [logoHeight, setLogoHeight] = React.useState(60);

  const handleImageLoad = (event) => {
    const img = event.target;
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    const aspectRatio = width / height;

    if (aspectRatio > 1) {
      setLogoHeight(40);
    } else {
      setLogoHeight(60);
    }
  };

  const dispatch = useDispatch()

  React.useEffect(() => {
    if ((isOnIframe || isOnWebView) && !selectedProduct) {
      window.addEventListener('message', getProductAndSet);
    }

    return () => {
      window.removeEventListener('message', getProductAndSet);
    };
  }, []);

  const getProductAndSet = (event) => {
    try {
      if (typeof event.data === "string") {
        const parsed = JSON.parse(event.data);
        if (parsed.type === "INIT_IFRAME") {
          const sku = parsed.productId;
          dispatch(setPreselectedSKU(sku));
        }
      }
    } catch (error) {
      console.log("getProductAndSet :: error", error);
    }
  }

  React.useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const rect = headerRef.current.getBoundingClientRect();
        setShowStickyBar(rect.bottom <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    if (isOnIframe || isOnWebView) {
      //@ts-ignore
      (isOnWebView ? window.ReactNativeWebView : window.parent).postMessage(JSON.stringify({
        type: "FRAME_LOADED"
      }), "*");
    }
  }, [])

  return (
    <>
      <Box ref={headerRef} paddingBlock={3} sx={{ boxShadow: "none", border: 0, width: "100%", top: 0, backgroundColor: "white", zIndex: 100000 }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            {/* Logo for larger screens */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <img
                src={companyLogo}
                alt="ardealy"
                onLoad={handleImageLoad}
                style={{ height: logoHeight, width: 'auto', objectFit: 'contain' }}
              />
            </Box>

            {/* Logo for smaller screens */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, justifyContent: "center", width: "100%" }}>
              <img
                src={companyLogo}
                alt="ardealy"
                onLoad={handleImageLoad}
                style={{ height: logoHeight, width: 'auto', objectFit: 'contain' }}
              />
            </Box>
          </Toolbar>

          {(isOnIframe || isOnWebView) && product && (
            <Typography variant="h6" className="ml-5" sx={{ marginTop: 1, position: "relative" }}>
              {t("SPACE_PICKER.SUBHEADER").replace("%PLACEHOLDER%", product.name)}
            </Typography>
          )}

        </Container>
      </Box>

      {showStickyBar && (
        <Box sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0px -2px 10px rgba(0,0,0,0.02)",
          padding: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 100000
        }}>
          <img className="h-8" src={companyLogo} alt="ardealy" height={30} />
        </Box>
      )}
    </>
  );
}