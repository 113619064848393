export const SET_PRODUCTS = "SET_PRODUCTS"

export const SET_FILTER = "SET_FILTER"
export const SET_STARTING_PAGE = "SET_STARTING_PAGE"
export const SET_WALLS_STARTING_PAGE = "SET_WALLS_STARTING_PAGE"
export const SET_FLOORS_STARTING_PAGE = "SET_FLOORS_STARTING_PAGE"

export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT"
export const SET_SELECTED_WALLS_PRODUCT = "SET_SELECTED_WALLS_PRODUCT"
export const SET_SELECTED_FLOORS_PRODUCT = "SET_SELECTED_FLOORS_PRODUCT"

export const SET_SELECTED_PRODUCT_RESULT = "SET_SELECTED_PRODUCT_RESULT"
export const SET_SELECTED_PRODUCT_RUG_RESULT = "SET_SELECTED_PRODUCT_RUG_RESULT"