import { createContext } from 'react';

export const WebViewContext = createContext(inWebView());


function isInWebView() {
    //@ts-ignore
    return typeof window !== "undefined" && window.ReactNativeWebView !== undefined;
  }

export const WebViewProvider = ({ children }) => {

  return (
    <WebViewContext.Provider value={inWebView()}>
      {children}
    </WebViewContext.Provider>
  )
}

function inWebView () {
    try {
        return isInWebView();
    } catch (e) {
        return true;
    }
}